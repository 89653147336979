import React, { useEffect, useState } from "react";
import { Formik, Form, Field, FieldArray, getIn } from "formik";
import { Link, useLocation, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner, faTrash } from "@fortawesome/free-solid-svg-icons";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { getTakeOffItemsForUi } from "../../store/reducer/takeOffItems/takeOffItemSlice";
import { getOpportunityById } from "../../store/reducer/opportunity/opportunitySlice";
import {
  deleteTakeOffItem,
  getTakeOffByDrawingId,
  updateDrawingTakeOff,
} from "../../store/reducer/drawing/drawingTakeOffSlice";
import { getSiteByDrawingId } from "../../store/reducer/drawing/drawingSiteSlice";
import { getDrawingById } from "../../store/reducer/drawing/drawingSlice";
import { proposalStatusByDrawingId } from "../../store/reducer/drawing/drawingProposalSlice";
import Notes from "../Notes/Notes";

function EditDrawingTakeOff() {
  const { id, drawing_id } = useParams();
  const location = useLocation();
  const dispatch = useDispatch();

  const { items = [] } = useSelector((state) => state.takeOffItems);

  const [opportunityData, setOpportunityData] = useState([]);
  const [drawingData, setdrawingData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [takeOffData, setTakeOffData] = useState([]);
  const [activeTab, setActiveTab] = useState(0);
  const [firstTimeData, setFirstTimeData] = useState([]);
  const [siteData, setSiteData] = useState([]);
  const [proposalStatus, setProposalStatus] = useState(true);

  const handleTabClick = (index) => {
    setActiveTab(index);
  };

  useEffect(() => {
    dispatch(getDrawingById(drawing_id)).then((result) => {
      if (result.payload) {
        setdrawingData(result.payload);
        dispatch(proposalStatusByDrawingId(result.payload.uniqueId)).then(
          (result) => {
            if (result.payload) {
              setProposalStatus(result.payload.hasData);
            } else {
              setProposalStatus(false);
            }
          }
        );
      }
    });

    dispatch(getOpportunityById(id)).then((result) => {
      if (result.payload) {
        setOpportunityData(result.payload);
      }
    });

    dispatch(getSiteByDrawingId(drawing_id)).then((result) => {
      if (result.payload) {
        setSiteData(result.payload);
      }
    });

    dispatch(getTakeOffItemsForUi());

    dispatch(getTakeOffByDrawingId(drawing_id)).then((result) => {
      if (result.payload) {
        setTakeOffData(result.payload);
        setLoading(false);
      } else {
        setLoading(false);
      }
    });
  }, []);

  useEffect(() => {
    let initialValue = [];
    if (takeOffData && takeOffData?.length) {
      takeOffData.map((one) => {
        let initialItems = {
          takeOffId: one.uniqueId,
          items: [],
        };
        if (!one?.area_items || !one?.area_items?.length) {
          items.map((each) => {
            if (
              each.is_default ||
              (siteData.roller_door_latch != "" && each.group_id == 498) ||
              (siteData.mesh_type != "" && each.group_id == 523)
            ) {
              initialItems.items.push({
                id: "",
                item_id: each.id,
                item_value: "",
              });
            }
          });
        }
        initialValue.push(initialItems);
      });
    }
    setFirstTimeData(initialValue);
  }, [items, takeOffData]);

  const ErrorMessage = ({ name }) => (
    <Field
      name={name}
      render={({ form }) => {
        const error = getIn(form.errors, name);
        const touch = getIn(form.touched, name);
        return touch && error ? (
          <div className="text-red-700">{error}</div>
        ) : null;
      }}
    />
  );

  const validationSchema = Yup.object().shape({
    area_id: Yup.string().required("required"),
    area_items: Yup.array()
      .of(
        Yup.object().shape({
          item_id: Yup.string().required("please select item"),
          item_value: Yup.string().required("please enter item value"),
          id: Yup.string(),
        })
      )
      .required("Must have area"),
  });

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    setSubmitting(true);
    values.drawing_id = drawing_id;
    values.drawing_uniqueId = drawingData.uniqueId;
    values.createdBy = JSON.parse(localStorage.getItem("userSession")).id;
    console.log(values);
    const formData = new FormData();
    Object.keys(values).forEach((fieldName) => {
      if (fieldName === "area_items") {
        formData.append(fieldName, JSON.stringify(values[fieldName]));
      } else {
        formData.append(fieldName, values[fieldName]);
      }
    });

    await dispatch(updateDrawingTakeOff({ data: formData, drawing_id })).then(
      (result) => {
        if (result.payload) {
          dispatch(getTakeOffByDrawingId(drawing_id)).then((result) => {
            if (result.payload) {
              setTakeOffData(result.payload);
            }
          });
          toast.success(result.payload.message);
        } else {
          toast.error(result.error.message);
        }
      }
    );
  };

  const handleDeleteItem = (id) => {
    dispatch(deleteTakeOffItem({ id })).then((result) => {
      if (result.payload) {
        toast.success(result.payload.message);
      } else {
        toast.error(result.error.message);
      }
    });
  };
  return (
    <>
      <div className="block md:grid grid-cols-3 items-center mb-[30px]">
        <div className="flex gap-[16px] items-center mb-[32px] md:mb-0">
          <Link to={`/opportunity/${id}`}>
            <div className="bg-[#F8F8F8] rounded-[10px] p-[8px]">
              <img
                className="w-[24px]"
                src={process.env.PUBLIC_URL + "/icons/back.png"}
                alt="back icon"
              />
            </div>
          </Link>
          <div className="flex flex-col">
            <span className="font-Inter font-[400] leading-[16px] text-[#9E9E9E] text-[14px]">
              Drawing Take Off / Edit
            </span>
            <span className="font-Inter font-[700] leading-[30px] text-[24px]">
              Take Off
            </span>
          </div>
        </div>
        <div className="font-Inter font-[500] leading-[30px] text-[18px] text-center">
          {opportunityData.id}-{opportunityData.opportunity_name}
        </div>
        <Notes type="drawing-takeoff" drawing_id={drawing_id} />
      </div>
      <div className="flex gap-[30px] bg-[#61b35436] p-2 rounded-lg mb-10 overflow-auto text-nowrap justify-between">
        <Link to={`/opportunity/${id}/drawing/${drawing_id}`}>
          <div
            className={`font-Inter text-[16px] font-[400] px-5 py-2 rounded-lg ${
              location.pathname === `/opportunity/${id}/drawing/${drawing_id}`
                ? "text-white bg-[#55A14A]"
                : "text-black hover:bg-[#55A14A] hover:text-white"
            }`}
          >
            Drawing Detail
          </div>
        </Link>
        <Link to={`/opportunity/${id}/drawing/${drawing_id}/site`}>
          <div
            className={`font-Inter text-[16px] font-[400] px-5 py-2 rounded-lg ${
              location.pathname ===
              `/opportunity/${id}/drawing/${drawing_id}/site`
                ? "text-white bg-[#55A14A]"
                : "text-black hover:bg-[#55A14A] hover:text-white"
            }`}
          >
            Site Detail
          </div>
        </Link>
        <Link to={`/opportunity/${id}/drawing/${drawing_id}/take-off`}>
          <div
            className={`font-Inter text-[16px] font-[400] px-5 py-2 rounded-lg ${
              location.pathname ===
              `/opportunity/${id}/drawing/${drawing_id}/take-off`
                ? "text-white bg-[#55A14A]"
                : "text-black hover:bg-[#55A14A] hover:text-white"
            }`}
          >
            Take Off
          </div>
        </Link>
        <Link to={`/opportunity/${id}/drawing/${drawing_id}/bom`}>
          <div
            className={`font-Inter text-[16px] font-[400] px-5 py-2 rounded-lg ${
              location.pathname ===
              `/opportunity/${id}/drawing/${drawing_id}/bom`
                ? "text-white bg-[#55A14A]"
                : "text-black hover:bg-[#55A14A] hover:text-white"
            }`}
          >
            BOM
          </div>
        </Link>
        <Link to={`/opportunity/${id}/drawing/${drawing_id}/install`}>
          <div
            className={`font-Inter text-[16px] font-[400] px-5 py-2 rounded-lg ${
              location.pathname ===
              `/opportunity/${id}/drawing/${drawing_id}/install`
                ? "text-white bg-[#55A14A]"
                : "text-black hover:bg-[#55A14A] hover:text-white"
            }`}
          >
            Install
          </div>
        </Link>
        <Link
          to={`/opportunity/${id}/drawing/${drawing_id}/additional-options`}
        >
          <div
            className={`font-Inter text-[16px] font-[400] px-5 py-2 rounded-lg ${
              location.pathname ===
              `/opportunity/${id}/drawing/${drawing_id}/additional-options`
                ? "text-white bg-[#55A14A]"
                : "text-black hover:bg-[#55A14A] hover:text-white"
            }`}
          >
            Additional Options
          </div>
        </Link>
        <Link to={`/opportunity/${id}/drawing/${drawing_id}/discount`}>
          <div
            className={`font-Inter text-[16px] font-[400] px-5 py-2 rounded-lg ${
              location.pathname ===
              `/opportunity/${id}/drawing/${drawing_id}/discount`
                ? "text-white bg-[#55A14A]"
                : "text-black hover:bg-[#55A14A] hover:text-white"
            }`}
          >
            Discount
          </div>
        </Link>
        <Link to={`/opportunity/${id}/drawing/${drawing_id}/pandl`}>
          <div
            className={`font-Inter text-[16px] font-[400] px-5 py-2 rounded-lg ${
              location.pathname ===
              `/opportunity/${id}/drawing/${drawing_id}/pandl`
                ? "text-white bg-[#55A14A]"
                : "text-black hover:bg-[#55A14A] hover:text-white"
            }`}
          >
            P&L
          </div>
        </Link>
        <Link to={`/opportunity/${id}/drawing/${drawing_id}/proposal`}>
          <div
            className={`font-Inter text-[16px] font-[400] px-5 py-2 rounded-lg ${
              location.pathname ===
              `/opportunity/${id}/drawing/${drawing_id}/proposal`
                ? "text-white bg-[#55A14A]"
                : "text-black hover:bg-[#55A14A] hover:text-white"
            }`}
          >
            Proposal
          </div>
        </Link>
        <Link to={`/opportunity/${id}/drawing/${drawing_id}/files`}>
          <div
            className={`font-Inter text-[16px] font-[400] px-5 py-2 rounded-lg ${
              location.pathname ===
              `/opportunity/${id}/drawing/${drawing_id}/files`
                ? "text-white bg-[#55A14A]"
                : "text-black hover:bg-[#55A14A] hover:text-white"
            }`}
          >
            Files
          </div>
        </Link>
        <Link to={`/opportunity/${id}/drawing/${drawing_id}/purchase-orders`}>
          <div
            className={`font-Inter text-[16px] font-[400] px-5 py-2 rounded-lg ${
              location.pathname ===
              `/opportunity/${id}/drawing/${drawing_id}/purchase-orders`
                ? "text-white bg-[#55A14A]"
                : "text-black hover:bg-[#55A14A] hover:text-white"
            }`}
          >
            Create PO
          </div>
        </Link>
        <Link
          to={`/opportunity/${id}/drawing/${drawing_id}/purchase-orders/history`}
        >
          <div
            className={`font-Inter text-[16px] font-[400] px-5 py-2 rounded-lg ${
              location.pathname ===
              `/opportunity/${id}/drawing/${drawing_id}/purchase-orders/history`
                ? "text-white bg-[#55A14A]"
                : "text-black hover:bg-[#55A14A] hover:text-white"
            }`}
          >
            PO History
          </div>
        </Link>
      </div>
      {/* code starts here */}
      {!loading ? (
        <>
          <div className="border border-gray-200 p-4 rounded-lg">
            <div className="grid grid-cols-5 gap-[20px] justify-between mb-2">
              <div className="col-span-4">
                <div className="flex gap-[30px] p-2 text-nowrap overflow-x-scroll">
                  {takeOffData.length
                    ? takeOffData.map((takeoff, index) => {
                        return (
                          <span
                            className={`flex justify-center border-b-4 py-2 px-5 cursor-pointer ${
                              activeTab === index
                                ? "text-[#55A14A] border-b-4 border-[#55A14A]"
                                : "text-black border-transparent hover:border-b-4 hover:border-[#55A14A] hover:text-[#55A14A]"
                            }`}
                            key={index}
                            onClick={() => handleTabClick(index)}
                            data_id={index}
                          >
                            {takeoff.area_name}
                          </span>
                        );
                      })
                    : null}
                </div>
              </div>
            </div>

            {takeOffData.length ? (
              takeOffData
                .filter((takeoff, index) => index === activeTab)
                .map((takeoff, index) => {
                  let firstTimeInitialData = firstTimeData.find(
                    (e) => e.takeOffId == takeoff.uniqueId
                  );
                  let initialValues = {
                    area_id: `${takeoff.id}`,
                    area_uniqueId: `${takeoff.uniqueId}`,
                    area_items: takeoff.area_items.length
                      ? takeoff.area_items
                      : firstTimeInitialData?.items || [
                          {
                            id: "",
                            item_id: "",
                            item_value: "",
                          },
                        ],
                  };

                  return (
                    <Formik
                      initialValues={initialValues}
                      validationSchema={validationSchema}
                      onSubmit={handleSubmit}
                      key={takeoff.area_id}
                      enableReinitialize
                    >
                      {({
                        isSubmitting,
                        values,
                        errors,
                        touched,
                        setFieldValue,
                        handleSubmit,
                      }) => (
                        <Form>
                          <FieldArray name="area_items">
                            {({ insert, remove, push }) => {
                              const areaItemsIdSet = new Set(
                                values?.area_items?.map(
                                  ({ item_id }) => +item_id
                                ) || []
                              );

                              return (
                                <div>
                                  <div className="grid grid-cols-3 p-2 gap-[20px] mb-2">
                                    <span>Item</span>
                                    <span>Value</span>
                                  </div>
                                  {values.area_items.length > 0 &&
                                    values.area_items.map((item, index) => {
                                      const itemOptions = items.find(
                                        (it) =>
                                          it.id ==
                                          item.item_id
                                      )?.options || [];
                                      
                                      return (
                                        <div
                                          key={index}
                                          className="md:grid md:grid-cols-3 gap-[20px] items-center rounded-[12px]"
                                        >
                                          <div className="mb-2 hidden">
                                            <div className="relative">
                                              <Field
                                                className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded-[12px] leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                                id={`area_items.${index}.id`}
                                                name={`area_items.${index}.id`}
                                              ></Field>
                                            </div>
                                          </div>
                                          <div className="mb-2">
                                            <div className="relative">
                                              <Field
                                                as="select"
                                                className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded-[12px] leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                                id={`area_items.${index}.item_id`}
                                                name={`area_items.${index}.item_id`}
                                              >
                                                <option value={""}>
                                                  Select Item
                                                </option>
                                                {items
                                                  .filter(
                                                    ({ id }) =>
                                                      item.item_id ||
                                                      !areaItemsIdSet.has(+id)
                                                  )
                                                  .map((option) => (
                                                    <option
                                                      key={option.id}
                                                      value={option.id}
                                                      selected={
                                                        `area_items.${index}.item_id` ===
                                                        option.id
                                                      }
                                                    >
                                                      {option.item_name}
                                                    </option>
                                                  ))}
                                              </Field>
                                              <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                                <svg
                                                  className="fill-current h-4 w-4"
                                                  xmlns="http://www.w3.org/2000/svg"
                                                  viewBox="0 0 20 20"
                                                >
                                                  <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                                                </svg>
                                              </div>
                                            </div>
                                            <ErrorMessage
                                              name={`area_items.${index}.item_id`}
                                            />
                                          </div>
                                          <div className="mb-2">
                                            {itemOptions?.length ? (
                                              <Field
                                                as="select"
                                                className="border rounded-lg px-2 py-1 w-full"
                                                name={`area_items.${index}.item_value`}
                                                onChange={(e) =>
                                                  setFieldValue(
                                                    `area_items.${index}.item_value`,
                                                    e.target.value
                                                  )
                                                }
                                                value={
                                                  values.area_items[index]
                                                    .item_value || ""
                                                }
                                              >
                                                <option value="">
                                                  Select Value
                                                </option>
                                                {itemOptions.map((option) => (
                                                  <option
                                                    key={option.id}
                                                    value={option.option_name}
                                                  >
                                                    {option.option_name}
                                                  </option>
                                                ))}
                                              </Field>
                                            ) : (
                                              <Field
                                                type="text"
                                                className="border rounded-lg px-2 py-1 w-full"
                                                name={`area_items.${index}.item_value`}
                                              />
                                            )}
                                            <ErrorMessage
                                              name={`area_items.${index}.item_value`}
                                            />
                                          </div>
                                          {values.area_items.length > 1 && (
                                            <div>
                                              <span
                                                className="text-red-400 hover:text-red-700 p-3 bg-white rounded-lg cursor-pointer"
                                                onClick={() => {
                                                  remove(index);
                                                  if (
                                                    values.area_items[index].id
                                                  ) {
                                                    handleDeleteItem(
                                                      values.area_items[index].id
                                                    );
                                                  }
                                                }}
                                              >
                                                <FontAwesomeIcon
                                                  icon={faTrash}
                                                  size="xl"
                                                />
                                              </span>
                                            </div>
                                          )}
                                        </div>)
                                    })}
                                  <div className="text-right p-[16px]">
                                    <span
                                      className="cursor-pointer text-[#55A14A]"
                                      onClick={() =>
                                        push({
                                          id: "",
                                          item_id: "",
                                          item_value: "",
                                        })
                                      }
                                    >
                                      + Add New Item
                                    </span>
                                  </div>
                                </div>
                              );
                            }}
                          </FieldArray>
                          <div className="flex gap-[24px] bg-[#F8F8F8] rounded-[12px] mt-5 p-4">
                            <div>
                              <button
                                type="submit"
                                name="submit"
                                className={`py-[12px] px-[48px] text-center text-white w-full rounded-[12px] text-[18px] ${
                                  isSubmitting || proposalStatus
                                    ? "bg-gray-300"
                                    : "bg-[#1C1C20]"
                                }`}
                                disabled={isSubmitting || proposalStatus}
                              >
                                {isSubmitting ? (
                                  <FontAwesomeIcon icon={faSpinner} spin />
                                ) : (
                                  "Save"
                                )}
                              </button>
                            </div>
                          </div>
                        </Form>
                      )}
                    </Formik>
                  );
                })
            ) : (
              <>
                <div className="text-center">
                  <span>No Data Available</span>
                </div>
              </>
            )}
          </div>
        </>
      ) : (
        <div className="text-center">
          <FontAwesomeIcon
            icon={faSpinner}
            size="2xl"
            style={{ color: "#55A14A" }}
            spin
          />
        </div>
      )}
    </>
  );
}

export default EditDrawingTakeOff;
