import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ReactPaginate from "react-paginate";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSpinner,
  faAngleRight,
  faAngleLeft,
  faEllipsis,
} from "@fortawesome/free-solid-svg-icons";
import { debounce } from "lodash";
import { listInvoices } from "../../store/reducer/invoices/invoiceSlice";
import moment from "moment";

const toCamelCase = (str) => {
  return str
    .replace(/_([a-z])/g, (_, letter) => letter.toUpperCase())
    .replace(/^[A-Z]/, (match) => match.toLowerCase());
};

// Normalize keys in an object
const normalizeKeys = (obj) => {
  if (typeof obj !== "object" || obj === null) {
    return obj;
  }

  if (Array.isArray(obj)) {
    return obj.map(normalizeKeys);
  }

  return Object.entries(obj).reduce((acc, [key, value]) => {
    const camelCaseKey = toCamelCase(key);
    acc[camelCaseKey] = normalizeKeys(value);
    return acc;
  }, {});
};

const getFirstFourCharacters = (str) => {
  if (typeof str !== "string") {
    return ""; // Return empty string if input is not a valid string
  }

  // Regular expression to check for exactly four digits or four digits followed by a hyphen
  const match = str.match(/^\d{4}(-.*)?$/);
  
  // If the pattern matches, return the first four digits; otherwise, return an empty string
  return match ? str.slice(0, 4) : "NA";
};

function ListInvoices() {
  const dispatch = useDispatch();
  const formRef = useRef(null);
  const {
    loading,
    invoices = [],
    totalCount,
  } = useSelector((state) => state.invoices);
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [isDeleted, setIsDeleted] = useState(false);
  const invoiceType = "ACCREC";

  useEffect(() => {
    dispatch(listInvoices({ search, page, limit, invoiceType }));
  }, []);

  const handleSearch = (selected) => {
    const page = selected + 1;
    setPage(page);
    dispatch(listInvoices({ search, page, limit, invoiceType }));
  };
  const debouncedSearch = useCallback(debounce(handleSearch, 800), [search]);

  const handlePageClick = ({ selected }) => {
    const page = selected + 1;
    setPage(page);
    dispatch(listInvoices({ search, page, limit, invoiceType }));
  };

  return (
    <div className="flex flex-col mt-[20px] gap-[40px]">
      <div className="">
        <div className="flex justify-between items-center">
          <div className="leading-[30px] font-Mulish font-[700] text-[24px]">
            <span className="">List Of Invoices</span>
          </div>
        </div>
        <div className="md:p-5 bg-[#F8F8F8] mt-[20px] rounded-l-[12px]">
          <div className="flex flex-col">
            <div className="md:flex justify-between items-center p-[12px]">
              <div className="relative w-full">
                <input
                  className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-4 pl-[60px] rounded-[12px] leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  type="text"
                  id="search"
                  name="search"
                  placeholder="Search here for any invoice number, opportunity, customer and email address"
                  onChange={(e) => {
                    setSearch(e.target.value);
                    debouncedSearch(e.target.value);
                  }}
                />
                <div className="pointer-events-none absolute inset-y-0 flex items-center px-5 text-gray-700">
                  <img
                    src={process.env.PUBLIC_URL + "/icons/search.png"}
                    alt=""
                  />
                </div>
              </div>
            </div>
            <div className="sm:rounded-lg mt-5 overflow-scroll">
              <table className="w-full">
                <thead className="bg-white">
                  <tr className="rounded-[12px]">
                    <th
                      scope="col"
                      className="px-6 py-3 text-left font-[Inter] font-[600] text-[14px] text-[#6D6D6F] leading-[20px] tracking-wider text-nowrap"
                    >
                      Number
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left font-[Inter] font-[600] text-[14px] text-[#6D6D6F] leading-[20px] tracking-wider text-nowrap"
                    >
                      Opportunity
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left font-[Inter] font-[600] text-[14px] text-[#6D6D6F] leading-[20px] tracking-wider text-nowrap"
                    >
                      Customer
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left font-[Inter] font-[600] text-[14px] text-[#6D6D6F] leading-[20px] tracking-wider text-nowrap"
                    >
                      Date
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left font-[Inter] font-[600] text-[14px] text-[#6D6D6F] leading-[20px] tracking-wider text-nowrap"
                    >
                      Due Date
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left font-[Inter] font-[600] text-[14px] text-[#6D6D6F] leading-[20px] tracking-wider text-nowrap"
                    >
                      Amount
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left font-[Inter] font-[600] text-[14px] text-[#6D6D6F] leading-[20px] tracking-wider text-nowrap"
                    >
                      Status
                    </th>
                  </tr>
                </thead>
                {!loading ? (
                  <tbody>
                    {invoices.map((invoice) => {
                      let data = JSON.parse(invoice.data);
                      data = normalizeKeys(data);
                      console.log(data);
                      let date, dueDate;

                      if (data.dateString) {
                        date = new Date(data.dateString);
                      } else if (data.date) date = new Date(data.date);
                      if (data.dueDateString) {
                        dueDate = new Date(data.dueDateString);
                      } else if (data.dueDate) dueDate = new Date(data.dueDate);

                      return (
                        <tr key={data.id}>
                          <td className="px-6 py-4 whitespace-nowrap text-[14px] text-[#6D6D6F] font-[600]">
                            {data.invoiceNumber}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-[14px] text-[#6D6D6F]">
                            {getFirstFourCharacters(data.reference || data.invoiceNumber)}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-[14px] text-[#6D6D6F]">
                            {data.contact.name}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-[14px] text-[#6D6D6F]">
                            {date ? moment(date).format("DD-MM-yyyy") : ""}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-[14px] text-[#6D6D6F]">
                            {dueDate
                              ? moment(dueDate).format("DD-MM-yyyy")
                              : ""}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-[14px] text-[#6D6D6F]">
                            {data.total?.toFixed(2)}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-[14px] text-[#6D6D6F]">
                            {data.status}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                ) : (
                  <tbody>
                    <tr>
                      <td className="text-center" colSpan={6}>
                        <FontAwesomeIcon
                          icon={faSpinner}
                          size="2xl"
                          style={{ color: "#55A14A" }}
                          spin
                        />
                      </td>
                    </tr>
                  </tbody>
                )}
              </table>
            </div>
          </div>
        </div>
      </div>
      <div className="flex justify-between items-center mt-[20px] gap-[40px] md:p-5 bg-[#F8F8F8] rounded-l-[12px]">
        <div className="hidden md:block font-Inter font-[300] text-nowrap">
          <span>
            Showing <b>{page}</b> of <b>{Math.ceil(totalCount / limit)}</b>{" "}
            pages
          </span>
        </div>
        <div className="w-full flex justify-end">
          <ReactPaginate
            className="react_pagination_ul"
            breakLabel={
              <span className="w-10 h-10 flex justify-center items-center bg-white rounded-md mr-4">
                <FontAwesomeIcon icon={faEllipsis} size="xl" />
              </span>
            }
            nextLabel={
              <span className="w-10 h-10 flex justify-center items-center bg-white rounded-md">
                <FontAwesomeIcon icon={faAngleRight} size="xl" />
              </span>
            }
            onPageChange={handlePageClick}
            pageCount={Math.ceil(totalCount / limit)}
            previousLabel={
              <span className="w-10 h-10 flex justify-center items-center bg-white rounded-md mr-4">
                <FontAwesomeIcon icon={faAngleLeft} size="xl" />
              </span>
            }
            activeClassName="bg-black text-white"
            pageClassName="block text-black  hover:bg-black hover:text-white rounded-md  mr-4"
            pageLinkClassName="w-10 h-10 flex justify-center items-center"
            containerClassName="flex items-center justify-center "
          />
        </div>
      </div>
    </div>
  );
}

export default ListInvoices;
