import React, { useEffect, useState } from "react";
import { Formik, Form, Field } from "formik";
import { Link, useNavigate, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { updateUser, getUserById } from "../../../store/reducer/user/userSlice";

function EditUser() {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [selectImg, setSelectedImg] = useState(null);
  const [userData, setUserData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    dispatch(getUserById(id)).then((result) => {
      if (result.payload) {
        setUserData(result.payload);
        setSelectedImg(result.payload.profile_img);
        setLoading(false);
      }
    });
  }, []);

  const validationSchema = Yup.object().shape({
    firstname: Yup.string().required("please enter firstname"),
    lastname: Yup.string().required("please enter lastname"),
    email: Yup.string()
      .email("Invalid email address")
      .required("please enter email"),
    role: Yup.string().required("please select role"),
    companyCar: Yup.boolean(),
    companyCarRegistration: Yup.string().test(
      "is-required-if-company-car",
      "Please enter car registration",
      function (value) {
        // Use this context to access other field values
        const { companyCar } = this.parent;
        if (companyCar) {
          return !!value; // Return true if value exists, false otherwise
        }
        return true; // Valid if companyCar is false
      }
    ),
    mileageRate: Yup.number().typeError("Mileage rate must be a number")
    .positive("Miles must be greater than zero"),
  });

  const initialValues = {
    firstname: userData?.firstname || "",
    lastname: userData?.lastname || "",
    email: userData?.email || "",
    password: "",
    bankname: userData?.bankname || "",
    role: userData?.role || "",
    status: userData?.status || "",
    profile_img: userData?.profile_img || null,
    companyCar: userData?.companyCar || 0,
    companyCarRegistration: userData?.companyCarRegistration || "",
    mileageRate: userData?.mileageRate || ""
  };

  function previewImage(imageInput) {
    const file = imageInput.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = function (e) {
        setSelectedImg(e.target.result);
      };
      reader.readAsDataURL(file);
    }
  }

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    setSubmitting(true);
    values.createdBy = JSON.parse(localStorage.getItem("userSession")).id;

    const formData = new FormData();
    Object.keys(values).forEach((fieldName) => {
      formData.append(fieldName, values[fieldName]);
    });

    await dispatch(updateUser({ data: formData, id })).then((result) => {
      if (result.payload) {
        toast.success(result.payload.message);
        navigate("/setting/user");
      } else {
        toast.error(result.error.message);
      }
    });
  };

  if (!loading) {
    return (
      <>
        <div className="flex justify-between mb-[30px]">
          <div className="flex gap-[16px] items-center">
            <Link to="/setting/user">
              <div className="bg-[#F8F8F8] rounded-[10px] p-[8px]">
                <img
                  className="w-[24px]"
                  src={process.env.PUBLIC_URL + "/icons/back.png"}
                  alt="back icon"
                />
              </div>
            </Link>
            <div className="flex flex-col">
              <span className="font-Inter font-[400] leading-[16px] text-[#9E9E9E] text-[14px]">
                User / Edit User
              </span>
              <span className="font-Inter font-[700] leading-[30px] text-[24px]">
                Edit User
              </span>
            </div>
          </div>
        </div>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({
            isSubmitting,
            values,
            errors,
            touched,
            setFieldValue,
            handleSubmit,
          }) => (
            <Form>
              <div className="block md:flex gap-[20px]">
                <div className="md:grid md:grid-cols-2 gap-[20px] bg-[#F8F8F8] rounded-[12px] p-6 w-full mb-[16px] md:w-[70%] md:mb-0">
                  <div className="mb-2">
                    <label
                      className="font-Inter font-[400] text-[15px] text-[#9E9E9E] leading-[17px]"
                      htmlFor="firstname"
                    >
                      First Name
                    </label>
                    <Field
                      className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded-[12px] leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      type="text"
                      id="firstname"
                      name="firstname"
                      onChange={(e) => {
                        setFieldValue("firstname", e.target.value);
                      }}
                    />
                    {errors.firstname && touched.firstname ? (
                      <div className="text-red-700">{errors.firstname}</div>
                    ) : null}
                  </div>
                  <div className="mb-2">
                    <label
                      className="font-Inter font-[400] text-[15px] text-[#9E9E9E] leading-[17px]"
                      htmlFor="lastname"
                    >
                      Last Name
                    </label>

                    <Field
                      className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded-[12px] leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      type="text"
                      id="lastname"
                      name="lastname"
                      onChange={(e) => {
                        setFieldValue("lastname", e.target.value);
                      }}
                    />
                    {errors.lastname && touched.lastname ? (
                      <div className="text-red-700">{errors.lastname}</div>
                    ) : null}
                  </div>
                  <div className="mb-2">
                    <label
                      className="font-Inter font-[400] text-[15px] text-[#9E9E9E] leading-[17px]"
                      htmlFor="email"
                    >
                      Email
                    </label>
                    <Field
                      className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded-[12px] leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      type="text"
                      id="email"
                      name="email"
                      disabled
                      onChange={(e) => {
                        setFieldValue("email", e.target.value);
                      }}
                    />
                    {errors.email && touched.email ? (
                      <div className="text-red-700">{errors.email}</div>
                    ) : null}
                  </div>
                  <div className="mb-2">
                    <label
                      className="font-Inter font-[400] text-[15px] text-[#9E9E9E] leading-[17px]"
                      htmlFor="password"
                    >
                      Password
                    </label>
                    <Field
                      type="text"
                      name="password"
                      className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded-[12px] leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      onChange={(e) => {
                        setFieldValue("password", e.target.value);
                      }}
                    />
                    {errors.password && touched.password ? (
                      <div className="text-red-700">{errors.password}</div>
                    ) : null}
                  </div>
                  <div className="mb-2">
                    <label
                      className="font-Inter font-[400] text-[15px] text-[#9E9E9E] leading-[17px]"
                      htmlFor="bankname"
                    >
                      Bank Name
                    </label>
                    <Field
                      className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded-[12px] leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      type="text"
                      id="bankname"
                      name="bankname"
                      onChange={(e) => {
                        setFieldValue("bankname", e.target.value);
                      }}
                    />
                  </div>
                  <div className="mb-2">
                    <label
                      className="font-Inter font-[400] text-[15px] text-[#9E9E9E] leading-[17px]"
                      htmlFor="mileageRate"
                    >
                      Mileage Rate
                    </label>
                    <Field
                      type="number"
                      name="mileageRate"
                      className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded-[12px] leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      onChange={(e) => {
                        setFieldValue("mileageRate", e.target.value);
                      }}
                    />
                    {errors.mileageRate && touched.mileageRate ? (
                      <div className="text-red-700">{errors.mileageRate}</div>
                    ) : null}
                  </div>
                  <div className="mb-2">
                    <label
                      className="font-Inter font-[400] text-[15px] text-[#9E9E9E] leading-[17px]"
                      htmlFor="role"
                    >
                      Role
                    </label>
                    <div className="relative">
                      <Field
                        as="select"
                        className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded-[12px] leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        id="role"
                        name="role"
                        onChange={(e) => {
                          setFieldValue("role", e.target.value);
                        }}
                      >
                        <option value={""}>Select Role</option>
                        <option value={1}>Super Admin</option>
                        <option value={2}>Admin</option>
                        <option value={3}>Manager</option>
                        <option value={4}>Sales</option>
                        <option value={5}>Project Manager</option>
                        <option value={6}>Finance</option>
                      </Field>
                      <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                        <svg
                          className="fill-current h-4 w-4"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                        >
                          <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                        </svg>
                      </div>
                    </div>
                    {errors.role && touched.role ? (
                      <div className="text-red-700">{errors.role}</div>
                    ) : null}
                  </div>
                  <div>
                    <label
                      className="font-Inter font-[400] text-[15px] text-[#9E9E9E] leading-[17px]"
                      htmlFor="companyCar"
                    >
                      Company Car
                    </label>
                    <div>
                      <label
                        htmlFor="companyCar"
                        className="inline-flex items-center px-5 py-2 cursor-pointer text-gray-800 bg-white rounded-[12px]"
                      >
                        <input
                          id="companyCar"
                          type="checkbox"
                          className="hidden peer"
                          name="companyCar"
                          defaultChecked={values.companyCar}
                          onChange={(e) => {
                            setFieldValue("companyCar", e.target.checked);
                          }}
                        />
                        <span className="px-4 py-1 rounded-lg border-gray-600 border peer-checked:border-0">
                          No
                        </span>
                        <span className="px-4 py-1 rounded-lg border-gray-600 peer-checked:border">
                          Yes
                        </span>
                      </label>
                    </div>
                  </div>
                  {values?.companyCar ? (
                    <div className="mb-2">
                      <label
                        className="font-Inter font-[400] text-[15px] text-[#9E9E9E] leading-[17px]"
                        htmlFor="companyCarRegistration"
                      >
                        Company Car Registration
                      </label>

                      <Field
                        className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded-[12px] leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        type="text"
                        id="companyCarRegistration"
                        name="companyCarRegistration"
                        onChange={(e) => {
                          setFieldValue(
                            "companyCarRegistration",
                            e.target.value
                          );
                        }}
                      />
                      {errors.companyCarRegistration &&
                      touched.companyCarRegistration ? (
                        <div className="text-red-700">
                          {errors.companyCarRegistration}
                        </div>
                      ) : null}
                    </div>
                  ) : null}
                  <div>
                    <label
                      className="font-Inter font-[400] text-[15px] text-[#9E9E9E] leading-[17px]"
                      htmlFor="status"
                    >
                      Status
                    </label>
                    <div>
                      <label
                        htmlFor="status"
                        className="inline-flex items-center px-5 py-2 cursor-pointer text-gray-800 bg-white rounded-[12px]"
                      >
                        <input
                          id="status"
                          type="checkbox"
                          className="hidden peer"
                          name="status"
                          defaultChecked={values.status === "Active"}
                          onChange={(e) => {
                            const checkedValue = e.target.checked
                              ? "Active"
                              : "Inactive";
                            setFieldValue("status", checkedValue);
                          }}
                        />
                        <span className="px-4 py-1 rounded-lg border-gray-600 border peer-checked:border-0">
                          Inactive
                        </span>
                        <span className="px-4 py-1 rounded-lg border-gray-600 peer-checked:border">
                          Active
                        </span>
                      </label>
                    </div>
                  </div>
                </div>
                <div className="w-full md:w-[30%] bg-[#F8F8F8] rounded-[12px] p-6">
                  <div className="max-w-sm mx-auto bg-white rounded-lg shadow-md overflow-hidden items-center">
                    <div
                      id="image-preview"
                      className="max-w-sm p-6 bg-white border-dashed border-2 border-gray-400 rounded-lg items-center mx-auto text-center cursor-pointer"
                    >
                      <input
                        id="profile_img"
                        type="file"
                        name="profile_img"
                        className="hidden"
                        accept="image/*"
                        onChange={(e) => {
                          previewImage(e);
                          setFieldValue(
                            "profile_img",
                            e.currentTarget.files[0]
                          );
                        }}
                      />

                      <label htmlFor="profile_img" className="cursor-pointer">
                        {selectImg ? (
                          <img
                            src={selectImg}
                            className="max-h-48 rounded-lg mx-auto"
                          />
                        ) : (
                          <>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth="1.5"
                              stroke="currentColor"
                              className="w-8 h-8 text-gray-700 mx-auto mb-4"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5m-13.5-9L12 3m0 0l4.5 4.5M12 3v13.5"
                              />
                            </svg>
                            <h5 className="mb-[30px] text-xl font-bold tracking-tight text-gray-700">
                              Upload picture
                            </h5>
                            <p className="font-normal text-sm text-gray-400 md:px-6">
                              Choose photo size should be less than{" "}
                              <b className="text-gray-600">20MB</b>
                            </p>
                            <p className="font-normal text-sm text-gray-400 md:px-6">
                              and should be in{" "}
                              <b className="text-gray-600">JPG, JPEG</b> format.
                            </p>
                            <span
                              id="filename"
                              className="text-gray-500 bg-gray-200 z-50"
                            ></span>
                          </>
                        )}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex gap-[24px] bg-[#F8F8F8] rounded-[12px] mt-5 p-4">
                <div>
                  <button
                    type="submit"
                    name="submit"
                    className={`py-[12px] px-[48px] text-center text-white w-full rounded-[12px] text-[18px] ${
                      isSubmitting ? "bg-gray-300" : "bg-[#1C1C20]"
                    }`}
                    disabled={isSubmitting}
                  >
                    {isSubmitting ? (
                      <FontAwesomeIcon icon={faSpinner} spin />
                    ) : (
                      "Submit"
                    )}
                  </button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </>
    );
  }
}

export default EditUser;
