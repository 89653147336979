import React, { useState, useEffect } from "react";
import { Formik, Form, Field } from "formik";
import { Link, useNavigate, useLocation, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { getDdOptionForUI } from "../../store/reducer/ddOption/ddOptionSlice";
import { getCountryForUi } from "../../store/reducer/country/countrySlice";
import {
  getCustomerById,
  updateCustomer,
} from "../../store/reducer/customers/customersSlice";

function EditCustomer() {
  const { id } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [customerData, setCustomerData] = useState([]);
  const [loading, setLoading] = useState(true);

  const { options = [] } = useSelector((state) => state.ddOptions);
  const { countries = [] } = useSelector((state) => state.countries);

  useEffect(() => {
    dispatch(getCustomerById(id)).then((result) => {
      if (result.payload) {
        setCustomerData(result.payload);
        setLoading(false);
      }
    });
    dispatch(getDdOptionForUI());
    dispatch(getCountryForUi());
  }, []);

  const validationSchema = Yup.object().shape({
    customer_name: Yup.string().required("please enter customer name"),
    trading_name: Yup.string().required("please enter trading name"),
    contact_person: Yup.string().required("please enter contact person name"),
    email: Yup.string()
      .email("Invalid email address")
      .required("please enter email"),
  });

  const initialValues = {
    customer_name: customerData?.customer_name || "",
    trading_name: customerData?.trading_name || "",
    contact_person: customerData?.contact_person || "",
    email: customerData?.email || "",
    phone: customerData?.phone || "",
    currency: customerData?.currency || "",
    vat: customerData?.vat || "",
    customer_vat: customerData?.customer_vat || "",
    eori_number: customerData?.eori_number || "",
    customer_type: customerData?.customer_type || "",
    industry: customerData?.industry || "",
    street: customerData?.street || "",
    city: customerData?.city || "",
    state: customerData?.state || "",
    post_code: customerData?.post_code || "",
    country: customerData?.country || "",
    status: customerData?.status || "",
  };

  const handleSubmit = async (values, { setSubmitting }) => {
    setSubmitting(true);
    values.updatedBy = JSON.parse(localStorage.getItem("userSession")).id;
    const formData = new FormData();
    Object.keys(values).forEach((fieldName) => {
      formData.append(fieldName, values[fieldName]);
    });

    await dispatch(updateCustomer({ data: formData, id })).then((result) => {
      if (result.payload) {
        toast.success(result.payload.message);
        navigate("/customers");
      } else {
        toast.error(result.error.message);
      }
    });
  };

  return (
    <>
      <div className="flex justify-between mb-[30px]">
        <div className="flex gap-[16px] items-center">
          <Link to="/customers">
            <div className="bg-[#F8F8F8] rounded-[10px] p-[8px]">
              <img
                className="w-[24px]"
                src={process.env.PUBLIC_URL + "/icons/back.png"}
                alt="back icon"
              />
            </div>
          </Link>
          <div className="flex flex-col">
            <span className="font-Inter font-[400] leading-[16px] text-[#9E9E9E] text-[14px]">
              Customer / Edit
            </span>
            <span className="font-Inter font-[700] leading-[30px] text-[24px]">
              Edit Customer
            </span>
          </div>
        </div>
      </div>
      <div className="flex gap-[30px] bg-[#61b35436] p-2 rounded-lg mb-10 overflow-scroll">
        <Link to={`/customer/${id}`}>
          <div
            className={`font-Inter text-[18px] font-[400] px-5 py-2 rounded-lg ${
              location.pathname == `/customer/${id}`
                ? "text-white bg-[#55A14A]"
                : "text-black hover:bg-[#55A14A] hover:text-white"
            }`}
          >
            Customer Detail
          </div>
        </Link>
        <Link to={`/customer/${id}/site`}>
          <div
            className={`font-Inter text-[18px] font-[400] px-5 py-2 rounded-lg ${
              location.pathname == `/customer/${id}/site`
                ? "text-white bg-[#55A14A]"
                : "text-black hover:bg-[#55A14A] hover:text-white"
            }`}
          >
            Sites
          </div>
        </Link>
        <Link to={`/customer/${id}/contacts`}>
          <div
            className={`font-Inter text-[18px] font-[400] px-5 py-2 rounded-lg ${
              location.pathname == `/customer/${id}/contacts`
                ? "text-white bg-[#55A14A]"
                : "text-black hover:bg-[#55A14A] hover:text-white"
            }`}
          >
            Contacts
          </div>
        </Link>
      </div>
      {!loading ? (
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({
            isSubmitting,
            values,
            errors,
            touched,
            setFieldValue,
            handleSubmit,
          }) => (
            <Form>
              <div className="flex gap-[20px]">
                <div className="md:grid md:grid-cols-3 gap-[20px] bg-[#F8F8F8] rounded-[12px] p-6 w-full">
                  <div className="mb-2">
                    <label
                      className="font-Inter font-[400] text-[15px] text-[#9E9E9E] leading-[17px]"
                      htmlFor="customer_name"
                    >
                      Customer Name
                    </label>
                    <Field
                      className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded-[12px] leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      type="text"
                      id="customer_name"
                      name="customer_name"
                      onChange={(e) => {
                        setFieldValue("customer_name", e.target.value);
                      }}
                    />
                    {errors.customer_name && touched.customer_name ? (
                      <div className="text-red-700">{errors.customer_name}</div>
                    ) : null}
                  </div>
                  <div className="mb-2">
                    <label
                      className="font-Inter font-[400] text-[15px] text-[#9E9E9E] leading-[17px]"
                      htmlFor="trading_name"
                    >
                      Trading Name
                    </label>

                    <Field
                      className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded-[12px] leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      type="text"
                      id="trading_name"
                      name="trading_name"
                      onChange={(e) => {
                        setFieldValue("trading_name", e.target.value);
                      }}
                    />
                    {errors.trading_name && touched.trading_name ? (
                      <div className="text-red-700">{errors.trading_name}</div>
                    ) : null}
                  </div>
                  <div className="mb-2">
                    <label
                      className="font-Inter font-[400] text-[15px] text-[#9E9E9E] leading-[17px]"
                      htmlFor="contact_person"
                    >
                      Contact Person
                    </label>

                    <Field
                      className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded-[12px] leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      type="text"
                      id="contact_person"
                      name="contact_person"
                      onChange={(e) => {
                        setFieldValue("contact_person", e.target.value);
                      }}
                    />
                    {errors.contact_person && touched.contact_person ? (
                      <div className="text-red-700">
                        {errors.contact_person}
                      </div>
                    ) : null}
                  </div>
                  <div className="mb-2">
                    <label
                      className="font-Inter font-[400] text-[15px] text-[#9E9E9E] leading-[17px]"
                      htmlFor="email"
                    >
                      Email
                    </label>
                    <Field
                      className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded-[12px] leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      type="text"
                      id="email"
                      name="email"
                      onChange={(e) => {
                        setFieldValue("email", e.target.value);
                      }}
                    />
                    {errors.email && touched.email ? (
                      <div className="text-red-700">{errors.email}</div>
                    ) : null}
                  </div>
                  <div className="mb-2">
                    <label
                      className="font-Inter font-[400] text-[15px] text-[#9E9E9E] leading-[17px]"
                      htmlFor="phone"
                    >
                      Phone
                    </label>
                    <Field
                      type="text"
                      name="phone"
                      className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded-[12px] leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      onChange={(e) => {
                        setFieldValue("phone", e.target.value);
                      }}
                    />
                    {errors.phone && touched.phone ? (
                      <div className="text-red-700">{errors.phone}</div>
                    ) : null}
                  </div>
                  <div className="mb-2">
                    <label
                      className="font-Inter font-[400] text-[15px] text-[#9E9E9E] leading-[17px]"
                      htmlFor="currency"
                    >
                      Currency
                    </label>
                    <div className="relative">
                      <Field
                        as="select"
                        className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded-[12px] leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        id="currency"
                        name="currency"
                        onChange={(e) => {
                          setFieldValue("currency", e.target.value);
                        }}
                      >
                        <option value={""}>Select currency</option>
                        {options
                          .filter(
                            (option) =>
                              option.ui_name === "Finance" &&
                              option.field_name === "Currency"
                          )
                          .map((option) => (
                            <option
                              key={option.id}
                              value={option.id}
                              selected={option.is_default ? true : false}
                            >
                              {option.field_code} {option.field_value}
                            </option>
                          ))}
                      </Field>
                      <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                        <svg
                          className="fill-current h-4 w-4"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                        >
                          <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                        </svg>
                      </div>
                    </div>
                    {errors.currency && touched.currency ? (
                      <div className="text-red-700">{errors.currency}</div>
                    ) : null}
                  </div>

                  <div className="mb-2">
                    <label
                      className="font-Inter font-[400] text-[15px] text-[#9E9E9E] leading-[17px]"
                      htmlFor="vat"
                    >
                      Vat
                    </label>
                    <div className="relative">
                      <Field
                        as="select"
                        className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded-[12px] leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        id="vat"
                        name="vat"
                        onChange={(e) => {
                          setFieldValue("vat", e.target.value);
                        }}
                      >
                        <option value={""}>Select vat</option>
                        {options
                          .filter(
                            (option) =>
                              option.ui_name === "Finance" &&
                              option.field_name === "Tax_Rate"
                          )
                          .map((option) => (
                            <option
                              key={option.id}
                              value={option.id}
                              selected={option.is_default ? true : false}
                            >
                              {option.field_code}
                            </option>
                          ))}
                      </Field>
                      <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                        <svg
                          className="fill-current h-4 w-4"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                        >
                          <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                        </svg>
                      </div>
                    </div>
                    {errors.vat && touched.vat ? (
                      <div className="text-red-700">{errors.vat}</div>
                    ) : null}
                  </div>

                  <div className="mb-2">
                    <label
                      className="font-Inter font-[400] text-[15px] text-[#9E9E9E] leading-[17px]"
                      htmlFor="customer_vat"
                    >
                      Customer VAT Number
                    </label>
                    <Field
                      type="text"
                      name="customer_vat"
                      className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded-[12px] leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      onChange={(e) => {
                        setFieldValue("customer_vat", e.target.value);
                      }}
                    />
                    {errors.customer_vat && touched.customer_vat ? (
                      <div className="text-red-700">{errors.customer_vat}</div>
                    ) : null}
                  </div>

                  <div className="mb-2">
                    <label
                      className="font-Inter font-[400] text-[15px] text-[#9E9E9E] leading-[17px]"
                      htmlFor="eori_number"
                    >
                      EORI Number
                    </label>
                    <Field
                      type="text"
                      name="eori_number"
                      className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded-[12px] leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      onChange={(e) => {
                        setFieldValue("eori_number", e.target.value);
                      }}
                    />
                    {errors.eori_number && touched.eori_number ? (
                      <div className="text-red-700">{errors.eori_number}</div>
                    ) : null}
                  </div>

                  <div className="mb-2">
                    <label
                      className="font-Inter font-[400] text-[15px] text-[#9E9E9E] leading-[17px]"
                      htmlFor="customer_type"
                    >
                      Customer Type
                    </label>
                    <div className="relative">
                      <Field
                        as="select"
                        className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded-[12px] leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        id="customer_type"
                        name="customer_type"
                        onChange={(e) => {
                          setFieldValue("customer_type", e.target.value);
                        }}
                      >
                        <option value={""}>Select customer type</option>
                        {options
                          .filter(
                            (option) =>
                              option.ui_name === "Customer" &&
                              option.field_name === "customer_type"
                          )
                          .map((option) => (
                            <option
                              key={option.id}
                              value={option.id}
                              selected={option.is_default ? true : false}
                            >
                              {option.field_code} {option.field_value}
                            </option>
                          ))}
                      </Field>
                      <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                        <svg
                          className="fill-current h-4 w-4"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                        >
                          <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                        </svg>
                      </div>
                    </div>
                  </div>

                  <div className="mb-2">
                    <label
                      className="font-Inter font-[400] text-[15px] text-[#9E9E9E] leading-[17px]"
                      htmlFor="industry"
                    >
                      Customer Industry
                    </label>
                    <div className="relative">
                      <Field
                        as="select"
                        className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded-[12px] leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        id="industry"
                        name="industry"
                        onChange={(e) => {
                          setFieldValue("industry", e.target.value);
                        }}
                      >
                        <option value={""}>Select customer industry</option>
                        {options
                          .filter(
                            (option) =>
                              option.ui_name === "Customer" &&
                              option.field_name === "industry"
                          )
                          .map((option) => (
                            <option
                              key={option.id}
                              value={option.id}
                              selected={option.is_default ? true : false}
                            >
                              {option.field_code} {option.field_value}
                            </option>
                          ))}
                      </Field>
                      <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                        <svg
                          className="fill-current h-4 w-4"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                        >
                          <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                        </svg>
                      </div>
                    </div>
                  </div>

                  <div className="mb-2">
                    <label
                      className="font-Inter font-[400] text-[15px] text-[#9E9E9E] leading-[17px]"
                      htmlFor="street"
                    >
                      Street
                    </label>
                    <Field
                      as="textarea"
                      name="street"
                      className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded-[12px] leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      onChange={(e) => {
                        setFieldValue("street", e.target.value);
                      }}
                    />
                  </div>
                  <div className="mb-2">
                    <label
                      className="font-Inter font-[400] text-[15px] text-[#9E9E9E] leading-[17px]"
                      htmlFor="city"
                    >
                      City
                    </label>
                    <Field
                      name="city"
                      className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded-[12px] leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      onChange={(e) => {
                        setFieldValue("city", e.target.value);
                      }}
                    />
                  </div>
                  <div className="mb-2">
                    <label
                      className="font-Inter font-[400] text-[15px] text-[#9E9E9E] leading-[17px]"
                      htmlFor="state"
                    >
                      State
                    </label>
                    <Field
                      name="state"
                      className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded-[12px] leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      onChange={(e) => {
                        setFieldValue("state", e.target.value);
                      }}
                    />
                  </div>
                  <div className="mb-2">
                    <label
                      className="font-Inter font-[400] text-[15px] text-[#9E9E9E] leading-[17px]"
                      htmlFor="post_code"
                    >
                      Post Code
                    </label>
                    <Field
                      name="post_code"
                      className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded-[12px] leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      onChange={(e) => {
                        setFieldValue("post_code", e.target.value);
                      }}
                    />
                  </div>
                  <div className="mb-2">
                    <label
                      className="font-Inter font-[400] text-[15px] text-[#9E9E9E] leading-[17px]"
                      htmlFor="country"
                    >
                      Country
                    </label>
                    <div className="relative">
                      <Field
                        as="select"
                        className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded-[12px] leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        id="country"
                        name="country"
                        onChange={(e) => {
                          setFieldValue("country", e.target.value);
                        }}
                      >
                        <option value={""}>Select country</option>
                        {countries.map((country) => (
                          <option key={country.id} value={country.id}>
                            {country.short_name}
                          </option>
                        ))}
                      </Field>
                      <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                        <svg
                          className="fill-current h-4 w-4"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                        >
                          <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                        </svg>
                      </div>
                    </div>
                  </div>

                  <div>
                    <label
                      className="font-Inter font-[400] text-[15px] text-[#9E9E9E] leading-[17px]"
                      htmlFor="status"
                    >
                      Status
                    </label>
                    <div>
                      <label
                        htmlFor="status"
                        className="inline-flex items-center px-5 py-2 cursor-pointer text-gray-800 bg-white rounded-[12px]"
                      >
                        <input
                          id="status"
                          type="checkbox"
                          className="hidden peer"
                          name="status"
                          defaultChecked={values.status === "Active"}
                          onChange={(e) => {
                            const checkedValue = e.target.checked
                              ? "Active"
                              : "Inactive";
                            setFieldValue("status", checkedValue);
                          }}
                        />
                        <span className="px-4 py-1 rounded-lg border-gray-600 border peer-checked:border-0">
                          Inactive
                        </span>
                        <span className="px-4 py-1 rounded-lg border-gray-600 peer-checked:border">
                          Active
                        </span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex gap-[24px] bg-[#F8F8F8] rounded-[12px] mt-5 p-4">
                <div>
                  <button
                    type="submit"
                    name="submit"
                    className={`py-[12px] px-[48px] text-center text-white w-full rounded-[12px] text-[18px] ${
                      isSubmitting ? "bg-gray-300" : "bg-[#1C1C20]"
                    }`}
                    disabled={isSubmitting}
                  >
                    {isSubmitting ? (
                      <FontAwesomeIcon icon={faSpinner} spin />
                    ) : (
                      "Save"
                    )}
                  </button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      ) : (
        <div className="text-center">
          <FontAwesomeIcon
            icon={faSpinner}
            size="2xl"
            style={{ color: "#55A14A" }}
            spin
          />
        </div>
      )}
    </>
  );
}

export default EditCustomer;
