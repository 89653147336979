import React, { useEffect, useState } from "react";
import { Formik, Form, Field, getIn } from "formik";
import { useNavigate, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner, faEye } from "@fortawesome/free-solid-svg-icons";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import {
  editCarMileage,
  getCarMileageById,
} from "../../store/reducer/carMileage/carMileageSlice";

function formatDateToYYYYMM(dateString) {
  const date = new Date(dateString);

  const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-indexed
  const year = date.getFullYear();

  // Format date to YYYY-MM
  return `${year}-${month}`;
}

function EditCarMileage() {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [fileName, setFileName] = useState("");
  const [carMileage, setCarMileage] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [viewPdf, setViewPdf] = useState("");
  const [loading, setLoading] = useState(true);

  const handleCloseModal = () => setIsOpen(false);

  const viewFile = (pdf) => {
    setViewPdf(pdf);
    setIsOpen(true);
  };

  const ErrorMessage = ({ name }) => (
    <Field
      name={name}
      render={({ form }) => {
        const error = getIn(form.errors, name);
        const touch = getIn(form.touched, name);
        return touch && error ? (
          <div className="text-red-700">{error}</div>
        ) : null;
      }}
    />
  );

  const validationSchema = Yup.object().shape({
    month: Yup.date().required("Please select month"),
    mileageReading: Yup.number()
      .typeError("Mileage reading must be a number")
      .required("Please enter Mileage reading")
      .integer("Mileage reading must be a whole number")
      .positive("Mileage reading must be greater than zero"),
    image: Yup.mixed()
      .required("Please upload an image")
      .test(
        "fileType",
        "Unsupported file format",
        (value) => value && ["image/jpeg", "image/png"].includes(value.type)
      ),
  });

  useEffect(() => {
    dispatch(getCarMileageById(id)).then((result) => {
      if (result.payload) {
        setCarMileage(result.payload);
        setLoading(false);
      }
    });
  }, []);

  const initialValues = {
    month: carMileage?.month ? formatDateToYYYYMM(carMileage?.month) : "",
    mileageReading: carMileage?.mileageReading,
    image_old: carMileage?.image,
    image: carMileage?.image,
  };

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    setSubmitting(true);

    const formData = new FormData();
    Object.keys(values).forEach((fieldName) => {
      formData.append(fieldName, values[fieldName]);
    });

    await dispatch(editCarMileage({ data: formData, id })).then((result) => {
      if (result.payload) {
        resetForm();
        navigate("/car-mileage");
        setFileName("");
        toast.success(result.payload.message);
      } else {
        toast.error(result.error.message);
      }
    });
  };

  return (
    <>
      {!loading ? (
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({
            isSubmitting,
            values,
            errors,
            touched,
            setFieldValue,
            handleSubmit,
          }) => {
            return (
              <Form>
                <div className="p-[16px] rounded-[12px] bg-[#F8F8F8] items-center mb-[30px]">
                  <h1 className="mb-[30px] text-[20px]">Edit Car Mileage</h1>
                  <div className="grid grid-cols-3 gap-[20px] mb-[20px] bg-[#FFF] rounded-lg">
                    <div className="px-2 py-3 font-[Inter] font-[600] text-[14px] text-[#6D6D6F] leading-[20px] tracking-wider ">
                      Month
                    </div>
                    <div className="px-2 py-3 font-[Inter] font-[600] text-[14px] text-[#6D6D6F] leading-[20px] tracking-wider text-nowrap">
                      Mileage Reading
                    </div>
                    <div className="px-2 py-3 font-[Inter] font-[600] text-[14px] text-[#6D6D6F] leading-[20px] tracking-wider text-nowrap">
                      Upload Image
                    </div>
                  </div>
                  <div className="grid grid-cols-3 gap-[20px] mb-[20px]">
                    <div className="mb-2">
                      <div className="relative">
                        <Field
                          className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded-[12px] leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id={`month`}
                          name={`month`}
                          type="month"
                        />
                        <ErrorMessage name={`month`} />
                      </div>
                    </div>
                    <div className="mb-2">
                      <div className="relative">
                        <Field
                          className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded-[12px] leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id={`mileageReading`}
                          name={`mileageReading`}
                          type="number"
                          step="1"
                          min="1"
                        />
                        <ErrorMessage name={`mileageReading`} />
                      </div>
                    </div>
                    <div className="mb-2">
                      <div className="flex items-center space-x-4">
                        <input
                          className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-2 px-4 pr-8 rounded-[12px] leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          key={Date.now()}
                          type="file"
                          name="image"
                          id="image"
                          accept="image/*"
                          capture="environment"
                          onChange={(e) => {
                            const file = e.currentTarget.files[0];
                            setFieldValue("image", file);
                            setFileName(file.name);
                          }}
                        />
                        {values.image_old && (
                          <div
                            className="bg-blue-100 rounded-lg p-2 cursor-pointer"
                            onClick={() => {
                              viewFile(values.image_old);
                            }}
                          >
                            <FontAwesomeIcon
                              icon={faEye}
                              size="lg"
                              style={{ color: "#397bd0" }}
                            />
                          </div>
                        )}
                      </div>
                      {fileName && (
                        <span className="text-gray-600 mt-2 block">
                          {fileName}
                        </span>
                      )}
                      <ErrorMessage name={`image`} />
                    </div>
                  </div>
                </div>

                <div className="flex gap-[24px] bg-[#F8F8F8] rounded-[12px] mt-5 p-4">
                  <div>
                    <button
                      type="submit"
                      name="submit"
                      className={`py-[12px] px-[48px] text-center text-white w-full rounded-[12px] text-[18px] ${
                        isSubmitting ? "bg-gray-300" : "bg-[#1C1C20]"
                      }`}
                      disabled={isSubmitting}
                    >
                      {isSubmitting ? (
                        <FontAwesomeIcon icon={faSpinner} spin />
                      ) : (
                        "Save"
                      )}
                    </button>
                  </div>
                </div>
                {isOpen && (
                  <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex items-center justify-center">
                    <div className="bg-white rounded-lg shadow-lg p-6 max-w-[800px] w-full h-[600px]">
                      <div className="flex justify-between items-center">
                        <button onClick={handleCloseModal} className="text-xl">
                          &times;
                        </button>
                      </div>
                      {viewPdf && (
                        <iframe
                          src={viewPdf}
                          className="w-full h-[500px] mt-4 border"
                          title="PDF Viewer"
                        />
                      )}
                    </div>
                  </div>
                )}
              </Form>
            );
          }}
        </Formik>
      ) : (
        <div className="text-center">
          <FontAwesomeIcon
            icon={faSpinner}
            size="2xl"
            style={{ color: "#55A14A" }}
            spin
          />
        </div>
      )}
    </>
  );
}

export default EditCarMileage;
