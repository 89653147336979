import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Formik, Form, Field } from "formik";
import { toast } from "react-toastify";
import ReactPaginate from "react-paginate";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSpinner,
  faAngleRight,
  faAngleLeft,
  faEllipsis,
  faPlus,
  faCheck,
} from "@fortawesome/free-solid-svg-icons";
import { debounce } from "lodash";
import {
  listBankTransactions,
  updateBankTransactionForExpense,
} from "../../store/reducer/bankTransactions/bankTransactionSlice";
import { getCustomerForUI } from "../../store/reducer/customers/customersSlice";
import { getDdOptionForUI } from "../../store/reducer/ddOption/ddOptionSlice";
import { addExpense } from "../../store/reducer/expenses/expensesSlice";
import { getOpportunityByCustomerId } from "../../store/reducer/opportunity/opportunitySlice";
import moment from "moment";
import * as Yup from "yup";

const toCamelCase = (str) => {
  return str
    .replace(/_([a-z])/g, (_, letter) => letter.toUpperCase())
    .replace(/^[A-Z]/, (match) => match.toLowerCase());
};

// Normalize keys in an object
const normalizeKeys = (obj) => {
  if (typeof obj !== "object" || obj === null) {
    return obj;
  }

  if (Array.isArray(obj)) {
    return obj.map(normalizeKeys);
  }

  return Object.entries(obj).reduce((acc, [key, value]) => {
    const camelCaseKey = toCamelCase(key);
    acc[camelCaseKey] = normalizeKeys(value);
    return acc;
  }, {});
};

const getFirstFourCharacters = (str) => {
  if (typeof str !== "string") {
    return ""; // Return empty string if input is not a valid string
  }
  return str.slice(0, 4);
};

function ListBankTransactions() {
  const dispatch = useDispatch();
  const {
    loading,
    bankTransactions = [],
    totalCount,
  } = useSelector((state) => state.bankTransactions);

  const [customerData, setCustomerData] = useState([]);
  const [opportunityData, setOpportunityData] = useState([]);
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [showModal, setShowModal] = useState(false);
  const [selectedTransaction, setSelectedTransaction] = useState(null);

  const { options = [] } = useSelector((state) => state.ddOptions);

  useEffect(() => {
    dispatch(getDdOptionForUI());
    dispatch(getCustomerForUI()).then((result) => {
      if (result.payload) {
        setCustomerData(result.payload);
      }
    });
  }, []);

  const getOppByCustomerId = (customer_id) => {
    dispatch(getOpportunityByCustomerId({ customer_id })).then((result) => {
      if (result.payload) {
        setOpportunityData(result.payload);
      }
    });
  };
  useEffect(() => {
    dispatch(listBankTransactions({ search, page, limit }));
  }, []);

  const handleSearch = (selected) => {
    const page = selected + 1;
    setPage(page);
    dispatch(listBankTransactions({ search, page, limit }));
  };
  const debouncedSearch = useCallback(debounce(handleSearch, 800), [search]);

  const handlePageClick = ({ selected }) => {
    const page = selected + 1;
    setPage(page);
    dispatch(listBankTransactions({ search, page, limit }));
  };

  const openExpenseModal = (transaction) => {
    setSelectedTransaction(transaction);
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
    setSelectedTransaction(null);
  };

  const getDefaultPaymentMethod = () => {
    const defaultPaymentMethod = options.find((option) => {
      return (
        option.ui_name === "Expense" &&
        option.field_name === "Payment Method" &&
        option.is_default === true
      );
    });
    return defaultPaymentMethod ? defaultPaymentMethod.id : "";
  };

  const getDefaultTax = () => {
    const defaultTax = options.find((option) => {
      return (
        option.ui_name === "Finance" &&
        option.field_name === "Tax_Rate" &&
        String(option.field_code).toLowerCase() === "no tax"
      );
    });
    return defaultTax ? defaultTax.id : "";
  };

  const getDefaultCurrency = () => {
    const defaultCurrency = options.find((option) => {
      return (
        option.ui_name === "Finance" &&
        option.field_name === "Currency" &&
        option.is_default === true
      );
    });
    return defaultCurrency ? defaultCurrency.id : "";
  };

  const validationSchema = Yup.object().shape({
    customer_id: Yup.string().required("please select customer"),
    opportunity_id: Yup.string().required("please select opportunity"),
    category: Yup.string().required("please select category"),
  });

  const initialValues = {
    customer_id: "",
    opportunity_id: "",
    category: "",
  };

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    setSubmitting(true);
    console.log("KLKJ:LKJ:LJLKJ:LKJ:LKJ:");
    values.customer_uniqueId = customerData.find(
      (customer) => customer.id == values.customer_id
    ).uniqueId;
    values.opportunity_uniqueId = opportunityData.find(
      (opportunity) => opportunity.id == values.opportunity_id
    ).uniqueId;
    values.createdBy = JSON.parse(localStorage.getItem("userSession")).id;

    let transactionData = JSON.parse(selectedTransaction.data);
    let date;
    if (transactionData.dateString) {
      date = new Date(transactionData.dateString);
    } else if (transactionData.date) date = new Date(transactionData.date);

    values.notes = `Bank: ${
      transactionData?.bankAccount?.name || "NA"
    }, Contact: ${transactionData?.contact?.name || "NA"}, Description: ${
      transactionData?.lineItems.map((item) => item?.description).join("-") ||
      "NA"
    }`;
    values.line_amount = transactionData?.lineItems
      .map((item) => item?.lineAmount || 0) // Extract line_amount from each item, default to 0 if missing
      .reduce((total, amount) => total + amount, 0); // Sum up all the line amounts
    values.expense_date = date;
    values.payment_method = getDefaultPaymentMethod();
    values.amount = transactionData?.total?.toFixed(2) || "NA";
    values.vat_amount = transactionData?.totalTax || 0.0;
    values.tax = getDefaultTax();
    values.currency = getDefaultCurrency();

    const formData = new FormData();
    Object.keys(values).forEach((fieldName) => {
      formData.append(fieldName, values[fieldName]);
    });

    await dispatch(addExpense(formData)).then((result) => {
      if (result.payload) {
        toast.success(result.payload.message);
        closeModal();
        dispatch(
          updateBankTransactionForExpense({
            transactionId: selectedTransaction?.id,
            expenseId: result.payload?.result?.id,
          })
        ).then((result) => {
          if (result.payload) {
            dispatch(listBankTransactions({ search, page, limit }));
          }
        });
      } else {
        toast.error(result.error.message);
      }
    });
  };

  return (
    <div className="flex flex-col mt-[20px] gap-[40px]">
      <div className="">
        <div className="flex justify-between items-center">
          <div className="leading-[30px] font-Mulish font-[700] text-[24px]">
            <span className="">List Of Bank Transactions</span>
          </div>
        </div>
        <div className="md:p-5 bg-[#F8F8F8] mt-[20px] rounded-l-[12px]">
          <div className="flex flex-col">
            <div className="md:flex justify-between items-center p-[12px]">
              <div className="relative w-full">
                <input
                  className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-4 pl-[60px] rounded-[12px] leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  type="text"
                  id="search"
                  name="search"
                  placeholder="Search here for any transaction"
                  onChange={(e) => {
                    setSearch(e.target.value);
                    debouncedSearch(e.target.value);
                  }}
                />
                <div className="pointer-events-none absolute inset-y-0 flex items-center px-5 text-gray-700">
                  <img
                    src={process.env.PUBLIC_URL + "/icons/search.png"}
                    alt=""
                  />
                </div>
              </div>
            </div>
            <div className="sm:rounded-lg mt-5 overflow-scroll">
              <table className="w-full">
                <thead className="bg-white">
                  <tr className="rounded-[12px]">
                    <th
                      scope="col"
                      className="px-6 py-3 text-left font-[Inter] font-[600] text-[14px] text-[#6D6D6F] leading-[20px] tracking-wider text-nowrap"
                    >
                      Bank
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left font-[Inter] font-[600] text-[14px] text-[#6D6D6F] leading-[20px] tracking-wider text-nowrap"
                    >
                      Contact
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left font-[Inter] font-[600] text-[14px] text-[#6D6D6F] leading-[20px] tracking-wider text-nowrap"
                    >
                      Description
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left font-[Inter] font-[600] text-[14px] text-[#6D6D6F] leading-[20px] tracking-wider text-nowrap"
                    >
                      Date
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left font-[Inter] font-[600] text-[14px] text-[#6D6D6F] leading-[20px] tracking-wider text-nowrap"
                    >
                      Amount
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left font-[Inter] font-[600] text-[14px] text-[#6D6D6F] leading-[20px] tracking-wider text-nowrap"
                    >
                      Add Expense
                    </th>
                  </tr>
                </thead>
                {!loading ? (
                  <tbody>
                    {bankTransactions.map((transaction) => {
                      let data = JSON.parse(transaction.data);
                      data = normalizeKeys(data);
                      let date;

                      if (data.dateString) {
                        date = new Date(data.dateString);
                      } else if (data.date) date = new Date(data.date);

                      return (
                        <tr key={data.id}>
                          <td className="px-6 py-4 whitespace-nowrap text-[14px] text-[#6D6D6F]">
                            {data?.bankAccount?.name || "NA"}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-[14px] text-[#6D6D6F]">
                            {data?.contact?.name || "NA"}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-[14px] text-[#6D6D6F]">
                            {data?.lineItems
                              .map((item) => item?.description)
                              .join("-") || "NA"}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-[14px] text-[#6D6D6F]">
                            {date ? moment(date).format("DD-MM-yyyy") : "NA"}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-[14px] text-[#6D6D6F]">
                            {data.total?.toFixed(2) || "NA"}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-[14px] text-[#6D6D6F]">
                            <button
                              name="addAsExpense"
                              className="py-[8px] px-[18px] bg-black text-center text-white rounded-[8px] text-[12px]"
                              onClick={() => openExpenseModal(transaction)}
                              disabled={transaction?.expenseId}
                            >
                              {transaction?.expenseId ? (
                                <FontAwesomeIcon icon={faCheck} />
                              ) : (
                                <FontAwesomeIcon icon={faPlus} />
                              )}
                            </button>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                ) : (
                  <tbody>
                    <tr>
                      <td className="text-center" colSpan={6}>
                        <FontAwesomeIcon
                          icon={faSpinner}
                          size="2xl"
                          style={{ color: "#55A14A" }}
                          spin
                        />
                      </td>
                    </tr>
                  </tbody>
                )}
              </table>
            </div>
          </div>
        </div>
      </div>

      {/* Modal */}
      {showModal && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-800 bg-opacity-75">
          <div className="bg-white p-6 rounded-lg w-[450px]">
            <h2 className="text-2xl font-bold mb-4">Add As Expense</h2>
            {(() => {
              let data = JSON.parse(selectedTransaction?.data);
              return (
                <>
                  <p>
                    <b>Bank:</b> {data?.bankAccount?.name || "NA"}
                  </p>
                  <p>
                    <b>Contact:</b> {data?.contact?.name || "NA"}
                  </p>
                  <p>
                    <b>Amount:</b> {data.total?.toFixed(2) || "NA"}
                  </p>
                </>
              );
            })()}
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
              enableReinitialize
            >
              {({
                isSubmitting,
                values,
                errors,
                touched,
                setFieldValue,
                handleSubmit,
              }) => (
                <Form>
                  <div className="flex flex-col gap-4 bg-gray-100 rounded-lg p-4">
                    <div className="mb-2">
                      <label
                        className="text-sm font-medium text-gray-600"
                        htmlFor="customer_id"
                      >
                        Customer
                      </label>
                      <div className="relative">
                        <Field
                          as="select"
                          className="block w-full bg-white border border-gray-300 text-gray-700 py-2 px-3 rounded-lg focus:outline-none focus:ring-2 focus:ring-gray-300"
                          id="customer_id"
                          name="customer_id"
                          onChange={(e) => {
                            setFieldValue("customer_id", e.target.value);
                            getOppByCustomerId(e.target.value);
                          }}
                        >
                          <option value="">Select Customer</option>
                          {customerData.map((customer) => (
                            <option value={customer.id} key={customer.id}>
                              {customer.customer_name}
                            </option>
                          ))}
                        </Field>
                        {errors.customer_id && touched.customer_id && (
                          <div className="text-red-600 text-xs">
                            {errors.customer_id}
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="mb-2">
                      <label
                        className="text-sm font-medium text-gray-600"
                        htmlFor="opportunity_id"
                      >
                        Opportunity
                      </label>
                      <div className="relative">
                        <Field
                          as="select"
                          className="block w-full bg-white border border-gray-300 text-gray-700 py-2 px-3 rounded-lg focus:outline-none focus:ring-2 focus:ring-gray-300"
                          id="opportunity_id"
                          name="opportunity_id"
                        >
                          <option value="">Select Opportunity</option>
                          {opportunityData.map((opportunity) => (
                            <option value={opportunity.id} key={opportunity.id}>
                              {opportunity.opportunity_name}
                            </option>
                          ))}
                        </Field>
                        {errors.opportunity_id && touched.opportunity_id && (
                          <div className="text-red-600 text-xs">
                            {errors.opportunity_id}
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="mb-2">
                      <label
                        className="text-sm font-medium text-gray-600"
                        htmlFor="category"
                      >
                        Category
                      </label>
                      <div className="relative">
                        <Field
                          as="select"
                          className="block w-full bg-white border border-gray-300 text-gray-700 py-2 px-3 rounded-lg focus:outline-none focus:ring-2 focus:ring-gray-300"
                          id="category"
                          name="category"
                        >
                          <option value="">Select Category</option>
                          {options
                            .filter(
                              (option) =>
                                option.ui_name === "Expense" &&
                                option.field_name === "Category"
                            )
                            .map((option) => (
                              <option key={option.id} value={option.id}>
                                {option.field_code}
                              </option>
                            ))}
                        </Field>
                        {errors.category && touched.category && (
                          <div className="text-red-600 text-xs">
                            {errors.category}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="flex justify-end mt-6">
                    <button
                      type="submit"
                      className={`px-6 py-2 mr-2 text-white rounded-lg ${
                        isSubmitting ? "bg-gray-300" : "bg-black"
                      }`}
                      disabled={isSubmitting}
                    >
                      {isSubmitting ? (
                        <FontAwesomeIcon icon={faSpinner} spin />
                      ) : (
                        "Add"
                      )}
                    </button>

                    <button
                      type="button"
                      className="px-6 py-2 text-white rounded-lg bg-black"
                      onClick={closeModal}
                    >
                      Cancel
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      )}

      {/* Pagination */}
      <div className="flex justify-center mt-6">
        <ReactPaginate
          previousLabel={<FontAwesomeIcon icon={faAngleLeft} />}
          nextLabel={<FontAwesomeIcon icon={faAngleRight} />}
          breakLabel={<FontAwesomeIcon icon={faEllipsis} />}
          pageCount={Math.ceil(totalCount / limit)}
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          onPageChange={handlePageClick}
          containerClassName={"pagination flex gap-2"}
          activeClassName={"active"}
          pageClassName={"px-4 py-2 bg-gray-200 rounded-lg"}
          previousClassName={"px-4 py-2 bg-gray-200 rounded-lg"}
          nextClassName={"px-4 py-2 bg-gray-200 rounded-lg"}
        />
      </div>
    </div>
  );
}

export default ListBankTransactions;
