import React, { useEffect, useState } from "react";
import { Formik, Form, Field, getIn } from "formik";
import { useNavigate, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { getOpportunityByCustomerId } from "../../store/reducer/opportunity/opportunitySlice";
import { getCustomerForUI } from "../../store/reducer/customers/customersSlice";
import {
  editBusinessMileage,
  getBusinessMileageById,
} from "../../store/reducer/businessMileage/businessMileageSlice";

const formatDate = (dateString) => {
  const date = new Date(dateString);
  return date.toISOString().split("T")[0]; // Format as YYYY-MM-DD
};

function EditBusinessMileage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();

  const [loading, setLoading] = useState(true);
  const [customerData, setCustomerData] = useState([]);
  const [journeyOpportunityData, setJourneyOpportunityData] = useState([]);
  const [journey, setJourney] = useState(null);

  useEffect(() => {
    dispatch(getBusinessMileageById(id)).then((result) => {
      if (result.payload) {
        setJourney(result.payload);
        setLoading(false);
        dispatch(
          getOpportunityByCustomerId({
            customer_id: result.payload?.customer_id,
          })
        ).then((result) => {
          if (result.payload) {
            setJourneyOpportunityData(result.payload);
          }
        });
      }
    });
    dispatch(getCustomerForUI()).then((result) => {
      if (result.payload) {
        setCustomerData(result.payload);
      }
    });
  }, []);

  const ErrorMessage = ({ name }) => (
    <Field
      name={name}
      render={({ form }) => {
        const error = getIn(form.errors, name);
        const touch = getIn(form.touched, name);
        return touch && error ? (
          <div className="text-red-700">{error}</div>
        ) : null;
      }}
    />
  );

  const validationSchema = Yup.object().shape({
    opportunity_id: Yup.number().required("please select opportunity"),
    customer_id: Yup.number().required("Please select customer"),
    date: Yup.date().required("Please select a date"),
    reason: Yup.string().required("Please enter a reason"),
    miles: Yup.number()
      .typeError("Miles must be a number")
      .required("Please enter miles")
      .integer("Miles must be a whole number")
      .positive("Miles must be greater than zero"),
  });

  const initialValues = {
    date: journey?.date ? formatDate(journey.date) : "",
    customer_id: journey?.customer_id,
    opportunity_id: journey?.opportunity_id,
    reason: journey?.reason,
    miles: journey?.miles?.toFixed(0),
  };

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    setSubmitting(true);
    values.updatedBy = JSON.parse(localStorage.getItem("userSession")).id;

    const formData = new FormData();
    Object.keys(values).forEach((fieldName) => {
      formData.append(fieldName, values[fieldName]);
    });
    await dispatch(
      editBusinessMileage({ data: formData, id: journey?.id })
    ).then((result) => {
      if (result.payload) {
        resetForm();
        navigate(`/business-mileage`);
        toast.success(result.payload.message);
      } else {
        toast.error(result.error.message);
      }
    });
  };

  return (
    <>
      {!loading ? (
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({
            isSubmitting,
            values,
            errors,
            touched,
            setFieldValue,
            handleSubmit,
          }) => {
            return (
              <Form>
                <div className="p-[16px] rounded-[12px] bg-[#F8F8F8] items-center mb-[30px]">
                  <h1 className="mb-[30px] text-[20px]">
                    Edit Business Mileage
                  </h1>
                  <div className="grid grid-cols-5 gap-[20px] mb-[20px] bg-[#FFF] rounded-lg">
                    <div className="px-2 py-3 font-[Inter] font-[600] text-[14px] text-[#6D6D6F] leading-[20px] tracking-wider ">
                      Date
                    </div>
                    <div className="px-2 py-3 font-[Inter] font-[600] text-[14px] text-[#6D6D6F] leading-[20px] tracking-wider text-nowrap">
                      Customer
                    </div>
                    <div className="px-2 py-3 font-[Inter] font-[600] text-[14px] text-[#6D6D6F] leading-[20px] tracking-wider text-nowrap">
                      Opportunity
                    </div>
                    <div className="px-2 py-3 font-[Inter] font-[600] text-[14px] text-[#6D6D6F] leading-[20px] tracking-wider text-nowrap">
                      Reason
                    </div>
                    <div className="px-2 py-3 font-[Inter] font-[600] text-[14px] text-[#6D6D6F] leading-[20px] tracking-wider text-nowrap">
                      Miles
                    </div>
                  </div>
                  <div className="grid grid-cols-5 gap-[20px] mb-[20px]">
                    <div className="mb-2">
                      <div className="relative">
                        <Field
                          className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded-[12px] leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id={`date`}
                          name={`date`}
                          type="date"
                        />
                        <ErrorMessage name={`date`} />
                      </div>
                    </div>
                    <div className="mb-2">
                      <div className="relative">
                        <Field
                          as="select"
                          className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded-[12px] leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id={`customer_id`}
                          name={`customer_id`}
                          onChange={(e) => {
                            setFieldValue(`customer_id`, e.target.value);
                            setJourneyOpportunityData([]);
                            dispatch(
                              getOpportunityByCustomerId({
                                customer_id: e.target.value,
                              })
                            ).then((result) => {
                              if (result.payload) {
                                setJourneyOpportunityData(result.payload);
                              }
                            });
                          }}
                        >
                          <option value={""}>Select Customer</option>
                          {customerData.map((customer) => (
                            <option value={customer.id} key={customer.id}>
                              {customer.customer_name}
                            </option>
                          ))}
                        </Field>
                        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                          <svg
                            className="fill-current h-4 w-4"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                          >
                            <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                          </svg>
                        </div>
                        <ErrorMessage name={`customer_id`} />
                      </div>
                    </div>
                    <div className="mb-2">
                      <div className="relative">
                        <Field
                          as="select"
                          className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded-[12px] leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id={`opportunity_id`}
                          name={`opportunity_id`}
                          onChange={(e) => {
                            setFieldValue(`opportunity_id`, e.target.value);
                          }}
                        >
                          <option value={""}>Select Opportunity</option>
                          {journeyOpportunityData?.map((opportunity) => (
                            <option value={opportunity.id} key={opportunity.id}>
                              {opportunity.opportunity_name}
                            </option>
                          ))}
                        </Field>
                        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                          <svg
                            className="fill-current h-4 w-4"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                          >
                            <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                          </svg>
                        </div>
                        <ErrorMessage name={`opportunity_id`} />
                      </div>
                    </div>
                    <div className="mb-2">
                      <div className="relative">
                        <Field
                          className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded-[12px] leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id={`reason`}
                          name={`reason`}
                          type="text"
                        />
                        <ErrorMessage name={`reason`} />
                      </div>
                    </div>
                    <div className="mb-2">
                      <div className="relative">
                        <Field
                          className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded-[12px] leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id={`miles`}
                          name={`miles`}
                          type="number"
                          step="1"
                          min="1"
                        />
                        <ErrorMessage name={`miles`} />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="flex gap-[24px] bg-[#F8F8F8] rounded-[12px] mt-5 p-4">
                  <div>
                    <button
                      type="submit"
                      name="submit"
                      className={`py-[12px] px-[48px] text-center text-white w-full rounded-[12px] text-[18px] ${
                        isSubmitting ? "bg-gray-300" : "bg-[#1C1C20]"
                      }`}
                      disabled={isSubmitting}
                    >
                      {isSubmitting ? (
                        <FontAwesomeIcon icon={faSpinner} spin />
                      ) : (
                        "Save"
                      )}
                    </button>
                  </div>
                </div>
              </Form>
            );
          }}
        </Formik>
      ) : (
        <div className="text-center">
          <FontAwesomeIcon
            icon={faSpinner}
            size="2xl"
            style={{ color: "#55A14A" }}
            spin
          />
        </div>
      )}
    </>
  );
}

export default EditBusinessMileage;
