import React from "react";
import { Link } from "react-router-dom";

function SettingList() {
  return (
    <>
      <div className="flex justify-between mb-[30px]">
        <div className="flex gap-[16px] items-center">
          <Link to="/dashboard">
            <div className="bg-[#F8F8F8] rounded-[10px] p-[8px]">
              <img
                className="w-[24px]"
                src={process.env.PUBLIC_URL + "/icons/back.png"}
                alt="back icon"
              />
            </div>
          </Link>
          <div className="flex flex-col">
            <span className="font-Inter font-[400] leading-[16px] text-[#9E9E9E] text-[14px]">
              Setting / List Setting
            </span>
            <span className="font-Inter font-[700] leading-[30px] text-[24px]">
              List Setting
            </span>
          </div>
        </div>
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3  gap-[30px] p-6">
        <div className="p-10 bg-gray-50 rounded-xl hover:shadow-xl hover:shadow-gray-400/60">
          <div className="flex gap-[20px] flex-col items-center justify-center">
            <img
              src={process.env.PUBLIC_URL + `/icons/user.png`}
              alt=""
              className="mt-4"
            />
            <span className="text-black text-[24px] font-600 font-Inter">
              User Management
            </span>
            <span>Manage who can uses the portal</span>
          </div>
          <Link to={"/setting/user"}>
            <div className="px-8 py-4 mt-8 bg-green-500 hover:bg-green-600 rounded-full flex justify-center">
              <img src={process.env.PUBLIC_URL + `/icons/arrow.png`} alt="" />
            </div>
          </Link>
        </div>

        <div className="p-10 bg-gray-50 rounded-xl hover:shadow-xl hover:shadow-gray-400/60">
          <div className="flex gap-[20px] flex-col items-center justify-center">
            <img
              src={process.env.PUBLIC_URL + `/icons/dropdown.png`}
              alt=""
              className="mt-4"
            />
            <span className="text-black text-[24px] font-600 font-Inter">
              DropDown Options
            </span>
            <span>Manage all your dropdown options</span>
          </div>
          <Link to={"/setting/dd-option"}>
            <div className="px-8 py-4 mt-8 bg-green-500 hover:bg-green-600 rounded-full flex justify-center">
              <img src={process.env.PUBLIC_URL + `/icons/arrow.png`} alt="" />
            </div>
          </Link>
        </div>

        <div className="p-10 bg-gray-50 rounded-xl hover:shadow-xl hover:shadow-gray-400/60">
          <div className="flex gap-[20px] flex-col items-center justify-center">
            <img
              src={process.env.PUBLIC_URL + `/icons/item.png`}
              alt=""
              className="mt-4"
            />
            <span className="text-black text-[24px] font-600 font-Inter">
              Item
            </span>
            <span>Manage all your items</span>
          </div>
          <Link to={"/setting/item"}>
            <div className="px-8 py-4 mt-8 bg-green-500 hover:bg-green-600 rounded-full flex justify-center">
              <img src={process.env.PUBLIC_URL + `/icons/arrow.png`} alt="" />
            </div>
          </Link>
        </div>

        <div className="p-10 bg-gray-50 rounded-xl hover:shadow-xl hover:shadow-gray-400/60">
          <div className="flex gap-[20px] flex-col items-center justify-center">
            <img
              src={process.env.PUBLIC_URL + `/icons/item.png`}
              alt=""
              className="mt-4"
            />
            <span className="text-black text-[24px] font-600 font-Inter">
              Item Price
            </span>
            <span>Manage your item price</span>
          </div>
          <Link to={"/setting/item-price"}>
            <div className="px-8 py-4 mt-8 bg-green-500 hover:bg-green-600 rounded-full flex justify-center">
              <img src={process.env.PUBLIC_URL + `/icons/arrow.png`} alt="" />
            </div>
          </Link>
        </div>

        <div className="p-10 bg-gray-50 rounded-xl hover:shadow-xl hover:shadow-gray-400/60">
          <div className="flex gap-[20px] flex-col items-center justify-center">
            <img
              src={process.env.PUBLIC_URL + `/icons/item.png`}
              alt=""
              className="mt-4"
            />
            <span className="text-black text-[24px] font-600 font-Inter">
              TakeOff Item
            </span>
            <span>Manage all your take off items</span>
          </div>
          <Link to={"/setting/takeoff/item"}>
            <div className="px-8 py-4 mt-8 bg-green-500 hover:bg-green-600 rounded-full flex justify-center">
              <img src={process.env.PUBLIC_URL + `/icons/arrow.png`} alt="" />
            </div>
          </Link>
        </div>

        <div className="p-10 bg-gray-50 rounded-xl hover:shadow-xl hover:shadow-gray-400/60">
          <div className="flex gap-[20px] flex-col items-center justify-center">
            <img
              src={process.env.PUBLIC_URL + `/icons/supplier.png`}
              alt=""
              className="mt-4"
            />
            <span className="text-black text-[24px] font-600 font-Inter">
              Supplier
            </span>
            <span>Manage your supplier</span>
          </div>
          <Link to={"/setting/supplier"}>
            <div className="px-8 py-4 mt-8 bg-green-500 hover:bg-green-600 rounded-full flex justify-center">
              <img src={process.env.PUBLIC_URL + `/icons/arrow.png`} alt="" />
            </div>
          </Link>
        </div>

        <div className="p-10 bg-gray-50 rounded-xl hover:shadow-xl hover:shadow-gray-400/60">
          <div className="flex gap-[20px] flex-col items-center justify-center">
            <img
              src={process.env.PUBLIC_URL + `/icons/supplier.png`}
              alt=""
              className="mt-4"
            />
            <span className="text-black text-[24px] font-600 font-Inter">
              Additional Option Costs
            </span>
            <span>Manage your option costs</span>
          </div>
          <Link to={"/setting/option-costs"}>
            <div className="px-8 py-4 mt-8 bg-green-500 hover:bg-green-600 rounded-full flex justify-center">
              <img src={process.env.PUBLIC_URL + `/icons/arrow.png`} alt="" />
            </div>
          </Link>
        </div>

        <div className="p-10 bg-gray-50 rounded-xl hover:shadow-xl hover:shadow-gray-400/60">
          <div className="flex gap-[20px] flex-col items-center justify-center">
            <img
              src={process.env.PUBLIC_URL + `/icons/supplier.png`}
              alt=""
              className="mt-4"
            />
            <span className="text-black text-[24px] font-600 font-Inter">
              Proposal Files
            </span>
            <span>Manage your proposal files</span>
          </div>
          <Link to={"/setting/proposal-files"}>
            <div className="px-8 py-4 mt-8 bg-green-500 hover:bg-green-600 rounded-full flex justify-center">
              <img src={process.env.PUBLIC_URL + `/icons/arrow.png`} alt="" />
            </div>
          </Link>
        </div>
      </div>
    </>
  );
}

export default SettingList;
