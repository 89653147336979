import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import ReactPaginate from "react-paginate";
import { Formik, Form, Field } from "formik";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSpinner,
  faAngleRight,
  faAngleLeft,
  faEllipsis,
} from "@fortawesome/free-solid-svg-icons";
import { debounce } from "lodash";
import { getDdOptionForUI } from "../../store/reducer/ddOption/ddOptionSlice";

import {
  getSourceAnalysisReport,
  searchSourceAnalysisReport,
} from "../../store/reducer/reports/sourceAnalysisSlice";

function SourceAnalysis() {
  const location = useLocation();
  const dispatch = useDispatch();
  const formRef = useRef(null);
  const {
    loading,
    data = [],
    totalCount,
  } = useSelector((state) => state.sourceAnalysisReport);
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);

  const { options = [] } = useSelector((state) => state.ddOptions);

  useEffect(() => {
    dispatch(getDdOptionForUI());
    dispatch(getSourceAnalysisReport({ search, page, limit }));
  }, []);

  const handleSearch = (search = "") => {
    dispatch(getSourceAnalysisReport({ search, page, limit }));
  };
  const debouncedSearch = useCallback(debounce(handleSearch, 800), [data]);

  const handlePageClick = ({ selected }) => {
    const page = selected + 1;
    setPage(page);
    dispatch(getSourceAnalysisReport({ search, page, limit }));
  };

  const handleResetFilterForm = () => {
    if (formRef.current) {
      formRef.current.resetForm();
      dispatch(getSourceAnalysisReport({ search, page, limit }));
    }
  };

  const handleSubmit = async (values, { setSubmitting }) => {
    setSubmitting(true);

    const expected_start_date = values.expected_start_date;
    const expected_close_date = values.expected_close_date;
    const lead_source = values.lead_source;

    await dispatch(
      searchSourceAnalysisReport({
        expected_start_date,
        expected_close_date,
        lead_source,
      })
    );
  };

  return (
    <>
      <div className="flex gap-[30px] bg-[#61b35436] p-2 rounded-lg mb-10 overflow-auto text-nowrap">
        <Link to={`/report/opportunity`}>
          <div
            className={`font-Inter text-[16px] font-[400] px-5 py-2 rounded-lg ${
              location.pathname === `/report/opportunity`
                ? "text-white bg-[#55A14A]"
                : "text-black hover:bg-[#55A14A] hover:text-white"
            }`}
          >
            Opportunity Report
          </div>
        </Link>
        <Link to={`/report/source-analysis`}>
          <div
            className={`font-Inter text-[16px] font-[400] px-5 py-2 rounded-lg ${
              location.pathname === `/report/source-analysis`
                ? "text-white bg-[#55A14A]"
                : "text-black hover:bg-[#55A14A] hover:text-white"
            }`}
          >
            Source Analysis
          </div>
        </Link>
      </div>
      <div className="flex flex-col mt-[20px] gap-[40px]">
        <div className="bg-grey md:flex gap-[10px]">
          <div className="w-full md:w-[28%] md:flex flex-col justify-between mb-[16px] md:mb-0">
            <div className="h-[114px] bg-[#5E6FF0] rounded-[12px] border-[10px] border-[#5E6FF0] p-[20px] flex items-center justify-between mb-3">
              <div className="flex flex-col gap-[10px]">
                <span className="text-[32px] leading-[40px] text-white font-Mulish font-[700]">
                  {totalCount}
                </span>
                <span className="text-[16px] text-white font-Mulish font-[700]">
                  Records returned
                </span>
              </div>
              <div>
                <img
                  src={process.env.PUBLIC_URL + "/icons/pad.png"}
                  className="w-[88px] h-[88px]"
                  alt=""
                />
              </div>
            </div>
          </div>
          <div className="w-full md:w-[72%] md:flex gap-[24px] flex-col bg-[#F8F8F8] rounded-[12px] p-6">
            <div className="flex flex-row justify-between pb-[20px] border-b border-[#E1E1E1] border-solid">
              <div>
                <span className="font-Inter font-[600] text-[18px] leading-[21px] text-[#1C1C20]">
                  Search Your Report
                </span>
              </div>
              <div>
                <span
                  className="cursor-pointer"
                  onClick={handleResetFilterForm}
                >
                  <img
                    src={process.env.PUBLIC_URL + "/icons/reset.png"}
                    alt=""
                    className="w-[24px] cursor-pointer"
                  />
                </span>
              </div>
            </div>
            <Formik
              innerRef={formRef}
              initialValues={{
                expected_start_date: "",
                expected_close_date: "",
                lead_source: "",
              }}
              // validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({ isSubmitting, errors, touched, setFieldValue }) => (
                <Form>
                  <div className="md:grid md:grid-cols-3 gap-3">
                    <div className="mb-2">
                      <label
                        className="font-Inter font-[400] text-[15px] text-[#9E9E9E] leading-[17px]"
                        htmlFor="expected_start_date"
                      >
                        Start Date
                      </label>
                      <Field
                        className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded-[12px] leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        type="date"
                        id="expected_start_date"
                        name="expected_start_date"
                        onChange={(e) => {
                          setFieldValue("expected_start_date", e.target.value);
                        }}
                      />
                      {errors.expected_start_date &&
                      touched.expected_start_date ? (
                        <div className="text-red-700">
                          {errors.expected_start_date}
                        </div>
                      ) : null}
                    </div>

                    <div className="mb-2">
                      <label
                        className="font-Inter font-[400] text-[15px] text-[#9E9E9E] leading-[17px]"
                        htmlFor="expected_close_date"
                      >
                        Close Date
                      </label>
                      <Field
                        className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded-[12px] leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        type="date"
                        id="expected_close_date"
                        name="expected_close_date"
                        onChange={(e) => {
                          setFieldValue("expected_close_date", e.target.value);
                        }}
                      />
                      {errors.expected_close_date &&
                      touched.expected_close_date ? (
                        <div className="text-red-700">
                          {errors.expected_close_date}
                        </div>
                      ) : null}
                    </div>

                    <div className="mb-2">
                      <label
                        className="font-Inter font-[400] text-[15px] text-[#9E9E9E] leading-[17px]"
                        htmlFor="lead_source"
                      >
                        Lead Source
                      </label>
                      <div className="relative">
                        <Field
                          as="select"
                          className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded-[12px] leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id="lead_source"
                          name="lead_source"
                          onChange={(e) => {
                            setFieldValue("lead_source", e.target.value);
                          }}
                        >
                          <option value={""}>Select Lead Source</option>
                          {options
                            .filter(
                              (option) =>
                                option.ui_name === "Opportunity" &&
                                option.field_name === "Lead_Source"
                            )
                            .map((option) => (
                              <option
                                key={option.id}
                                value={option.id}
                                selected={option.is_default ? true : false}
                              >
                                {option.field_code}
                              </option>
                            ))}
                        </Field>
                        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                          <svg
                            className="fill-current h-4 w-4"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                          >
                            <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                          </svg>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex bg-[#F8F8F8] rounded-[12px] mt-2">
                    <div>
                      <button
                        type="submit"
                        name="submit"
                        className={`py-[10px] px-[70px] text-center text-[#55A14A] border border-[#55A14A] w-full rounded-[12px] text-[16px] ${
                          isSubmitting ? "bg-gray-300" : "bg-white"
                        }`}
                        disabled={isSubmitting}
                      >
                        {isSubmitting ? (
                          <FontAwesomeIcon icon={faSpinner} spin />
                        ) : (
                          "Search"
                        )}
                      </button>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
          {/* <div className="w-[30%] bg-[#000] rounded-[12px]"></div> */}
        </div>
        <div className="">
          <div className="md:p-5 bg-[#F8F8F8] mt-[20px] rounded-l-[12px]">
            <div className="flex flex-col">
              <div className="md:flex justify-between items-center p-[12px]">
                <div className="relative">
                  <input
                    className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-4 pl-[60px] rounded-[12px] leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    type="text"
                    id="search"
                    name="search"
                    onChange={(e) => {
                      debouncedSearch(e.target.value);
                    }}
                  />
                  <div className="pointer-events-none absolute inset-y-0 flex items-center px-5 text-gray-700">
                    <img
                      src={process.env.PUBLIC_URL + "/icons/search.png"}
                      alt=""
                    />
                  </div>
                </div>
              </div>
              <div className="sm:rounded-lg mt-5 overflow-scroll">
                <table className="w-full">
                  <thead className="bg-white">
                    <tr className="rounded-[12px]">
                      <th
                        scope="col"
                        className="px-6 py-3 text-left font-[Inter] font-[600] text-[14px] text-[#6D6D6F] leading-[20px] tracking-wider text-nowrap"
                      >
                        Customer
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left font-[Inter] font-[600] text-[14px] text-[#6D6D6F] leading-[20px] tracking-wider text-nowrap"
                      >
                        Opportunity
                      </th>

                      <th
                        scope="col"
                        className="px-6 py-3 text-left font-[Inter] font-[600] text-[14px] text-[#6D6D6F] leading-[20px] tracking-wider text-nowrap"
                      >
                        Job Type
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left font-[Inter] font-[600] text-[14px] text-[#6D6D6F] leading-[20px] tracking-wider text-nowrap"
                      >
                        Sales Executive
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left font-[Inter] font-[600] text-[14px] text-[#6D6D6F] leading-[20px] tracking-wider text-nowrap"
                      >
                        Lead Source
                      </th>
                    </tr>
                  </thead>
                  {!loading ? (
                    <tbody>
                      {data.map((opportunity) => (
                        <tr key={opportunity.id}>
                          <td className="px-6 py-4 whitespace-nowrap text-[14px] text-[#6D6D6F]">
                            {opportunity.customer_name}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-[14px] text-[#6D6D6F]">
                            {opportunity.opportunity_name}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-[14px] text-[#6D6D6F]">
                            {opportunity.job_type_name}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-[14px] text-[#6D6D6F]">
                            {opportunity.sales_executive}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-[14px] text-[#6D6D6F]">
                            {opportunity.lead_source_name}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  ) : (
                    <div className="text-center">
                      <FontAwesomeIcon
                        icon={faSpinner}
                        size="2xl"
                        style={{ color: "#55A14A" }}
                        spin
                      />
                    </div>
                  )}
                </table>
              </div>
            </div>
          </div>
        </div>
        <div className="flex justify-between items-center mt-[20px] gap-[40px] md:p-5 bg-[#F8F8F8] rounded-l-[12px]">
          <div className="hidden md:block font-Inter font-[300] text-nowrap">
            <span>
              Showing <b>{page}</b> of <b>{Math.ceil(totalCount / limit)}</b>{" "}
              pages
            </span>
          </div>
          <div className="w-full flex justify-end">
            <ReactPaginate
              className="react_pagination_ul"
              breakLabel={
                <span className="w-10 h-10 flex justify-center items-center bg-white rounded-md mr-4">
                  <FontAwesomeIcon icon={faEllipsis} size="xl" />
                </span>
              }
              nextLabel={
                <span className="w-10 h-10 flex justify-center items-center bg-white rounded-md">
                  <FontAwesomeIcon icon={faAngleRight} size="xl" />
                </span>
              }
              onPageChange={handlePageClick}
              pageCount={Math.ceil(totalCount / limit)}
              previousLabel={
                <span className="w-10 h-10 flex justify-center items-center bg-white rounded-md mr-4">
                  <FontAwesomeIcon icon={faAngleLeft} size="xl" />
                </span>
              }
              activeClassName="bg-black text-white"
              pageClassName="block text-black  hover:bg-black hover:text-white rounded-md  mr-4"
              pageLinkClassName="w-10 h-10 flex justify-center items-center"
              containerClassName="flex items-center justify-center "
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default SourceAnalysis;
