import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { api } from "../../../api/ApiCall";

export const listCustomers = createAsyncThunk(
  "listCustomers",
  async ({ search = "", page = 1, limit = 10 }) => {
    const request = await api(
      `api/admin/customers?search=${search}&page=${page}&limit=${limit}`,
      "",
      "get"
    );
    return request;
  }
);

export const getCustomerById = createAsyncThunk(
  "getCustomerById",
  async (data) => {
    const request = await api(`api/admin/customer/${data}`, "", "get");
    return request;
  }
);

export const addCustomer = createAsyncThunk("addCustomer", async (data) => {
  const request = await api("api/admin/customer", data, "post");
  return request;
});

export const updateCustomer = createAsyncThunk(
  "updateCustomer",
  async ({ data, id }) => {
    const request = await api(`api/admin/customer/${id}`, data, "post");
    return request;
  }
);

export const getCustomerForUI = createAsyncThunk(
  "getCustomerForUI",
  async () => {
    const request = await api(`api/admin/customersforui`, "", "get");
    return request;
  }
);

export const deleteCustomer = createAsyncThunk("deleteCustomer", async (id) => {
  const request = await api(`api/admin/customer/${id}`, {}, "delete");
  return request;
});

const customersSlice = createSlice({
  name: "customers",
  initialState: {
    loading: false,
    customers: [],
    success: null,
    error: null,
    totalCount: 0,
  },
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(listCustomers.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(listCustomers.fulfilled, (state, action) => {
        state.customers = action?.payload?.rows;
        state.totalCount = action?.payload.count;
        state.loading = false;
        state.error = null;
      })
      .addCase(listCustomers.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(addCustomer.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(addCustomer.fulfilled, (state, action) => {
        state.loading = false;
        state.customers = action.payload;
        state.error = null;
      })
      .addCase(addCustomer.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(updateCustomer.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateCustomer.fulfilled, (state, action) => {
        state.loading = false;
        // Update the user in the users array
        state.customers = state.customers.map((option) => {
          if (option.id === action.payload.id) {
            return action.payload;
          }
          return option;
        });
        state.success = action.payload.message;
        state.error = null;
      })
      .addCase(updateCustomer.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(deleteCustomer.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteCustomer.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
      })
      .addCase(deleteCustomer.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});
export default customersSlice.reducer;
