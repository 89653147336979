import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import ReactPaginate from "react-paginate";
import { Formik, Form, Field, FieldArray, getIn } from "formik";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSpinner,
  faAngleRight,
  faAngleLeft,
  faEllipsis,
} from "@fortawesome/free-solid-svg-icons";
import * as Yup from "yup";
import { debounce } from "lodash";
import { getCustomerForUI } from "../../store/reducer/customers/customersSlice";
import { getDdOptionForUI } from "../../store/reducer/ddOption/ddOptionSlice";
import { getUserForUI } from "../../store/reducer/user/userSlice";
import {
  deleteOpportunity,
  listOpportunities,
  searchOpportunities,
  updateBulkOpportunity
} from "../../store/reducer/opportunity/opportunitySlice";

function ListOpportunity() {
  const dispatch = useDispatch();
  const formRef = useRef(null);
  const {
    loading,
    opportunities = [],
    totalCount,
    type,
    totalEstimatedValue,
  } = useSelector((state) => state.opportunity);
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [isDeleted, setIsDeleted] = useState(false);
  const [filterData, setFilterData] = useState({});

  const { options = [] } = useSelector((state) => state.ddOptions);

  const [customerData, setCustomerData] = useState([]);
  const [userData, setUserData] = useState([]);
  
  const ErrorMessage = ({ name }) => (
    <Field
      name={name}
      render={({ form }) => {
        const error = getIn(form.errors, name);
        const touch = getIn(form.touched, name);
        return touch && error ? (
          <div className="text-red-700">{error}</div>
        ) : null;
      }}
    />
  );

  useEffect(() => {
    dispatch(getCustomerForUI()).then((result) => {
      if (result.payload) {
        setCustomerData(result.payload);
      }
    });
    dispatch(getUserForUI()).then((result) => {
      if (result.payload) {
        setUserData(result.payload);
      }
    });
    dispatch(getDdOptionForUI());
    dispatch(listOpportunities({ search, page, limit, is_deleted: isDeleted }));
  }, []);

  const handleSearch = (search = "") => {
    dispatch(listOpportunities({ search, page, limit, is_deleted: isDeleted }));
  };
  const debouncedSearch = useCallback(debounce(handleSearch, 800), [
    opportunities,
  ]);

  const handlePageClick = ({ selected }) => {
    const page = selected + 1;
    setPage(page);
    if (type === "filter") {
      dispatch(
        searchOpportunities({
          customer_id: filterData.customer_id,
          sales_executive: filterData.sales_executive,
          progress_status: filterData.progress_status,
          win_probability: filterData.win_probability,
          expected_close_date: filterData.expected_close_date,
          next_contact_date: filterData.next_contact_date,
          page,
          limit,
        })
      );
    } else {
      dispatch(
        listOpportunities({ search, page, limit, is_deleted: isDeleted })
      );
    }
  };

  const showDeletedRecord = () => {
    dispatch(
      listOpportunities({ search, page, limit, is_deleted: !isDeleted })
    );
    setIsDeleted(!isDeleted);
  };

  const handleResetFilterForm = async () => {
    setPage(1);
    if (formRef.current) {
      formRef.current.resetForm();
      console.log(page);
      await dispatch(
        listOpportunities({ search, page: 1, limit, is_deleted: isDeleted })
      );
    }
  };

  const handleSubmit = async (values, { setSubmitting }) => {
    setSubmitting(true);

    const customer_id = values.customer_id;
    const sales_executive = values.sales_executive;
    const progress_status = values.progress_status;
    const win_probability = values.win_probability;
    const expected_close_date = values.expected_close_date;
    const next_contact_date = values.next_contact_date;

    setFilterData({
      customer_id: customer_id,
      sales_executive: sales_executive,
      progress_status: progress_status,
      win_probability: win_probability,
      expected_close_date: expected_close_date,
      next_contact_date: next_contact_date,
    });

    await dispatch(
      searchOpportunities({
        customer_id,
        sales_executive,
        progress_status,
        win_probability,
        expected_close_date,
        next_contact_date,
        page,
        limit,
      })
    );
  };

  const handleDelete = async (uniqueId) => {
    await dispatch(deleteOpportunity(uniqueId)).then((result) => {
      if (result.payload) {
        dispatch(
          listOpportunities({ search, page: 1, limit, is_deleted: isDeleted })
        );
        toast.success(result.payload.message);
      } else {
        toast.error(result.error.message);
      }
    });
  };

  const handleBulkEditSubmit = async (values, { setSubmitting, resetForm }) => {
    setSubmitting(true);
    values.updatedBy = JSON.parse(localStorage.getItem("userSession")).id;
    const formData = new FormData();
    Object.keys(values).forEach((fieldName) => {
      if (fieldName === "opportunities") {
        formData.append(fieldName, JSON.stringify(values[fieldName]));
      } else {
        formData.append(fieldName, values[fieldName]);
      }
    });

    await dispatch(updateBulkOpportunity(formData)).then(
      (result) => {
        if (result.payload) {
          toast.success(result.payload.message);
        } else {
          toast.error(result.error.message);
        }
      }
    );
  };

  const validationSchema = Yup.object().shape({
    opportunities: Yup.array()
      .of(
        Yup.object().shape({
          estimated_value: Yup.number()
            .typeError("Must be a number")
            .min(0, "Estimated value must be 0 or greater")
            .required("Required"),
          expected_close_date: Yup.date()
            .required("Expected close date is required")
            .typeError("Must be a valid date")
            .required("Required"),
          next_contact_date: Yup.date()
            .required("Next contact date is required")
            .typeError("Must be a valid date")
            .required("Required"),
          progress_status: Yup.number()
            .integer("Progress status must be an integer")
            .min(1, "Progress status must be greater than 0")
            .required("Required"),
          win_probability: Yup.number()
            .integer("Win probability must be an integer")
            .min(1, "Win probability must be greater than 0")
            .required("Required"),
        })
      )
      .required("Must have opportunity"),
  });

  return (
    <div className="flex flex-col mt-[20px] gap-[40px]">
      <div className="bg-grey md:flex gap-[10px]">
        <div className="w-full md:w-[28%] md:flex flex-col justify-between mb-[16px] md:mb-0">
          <div className="h-[114px] bg-[#5E6FF0] rounded-[12px] border-[10px] border-[#5E6FF0] p-[20px] flex items-center justify-between mb-3">
            <div className="flex flex-col gap-[10px]">
              <span className="text-[32px] leading-[40px] text-white font-Mulish font-[700]">
                {totalCount}
              </span>
              <span className="text-[16px] text-white font-Mulish font-[700]">
                Records returned
              </span>
            </div>
            <div>
              <img
                src={process.env.PUBLIC_URL + "/icons/pad.png"}
                className="w-[88px] h-[88px]"
                alt=""
              />
            </div>
          </div>
          <div className="h-[114px] bg-[#BFF05E] rounded-[12px] border-[10px] border-[#BFF05E] p-[20px] flex items-center justify-between mb-3">
            <div className="flex flex-col gap-[10px] order-2">
              <span className="text-[32px] leading-[40px] text-black font-Mulish font-[700]">
                {totalEstimatedValue}
              </span>
              <span className="text-[16px] text-black font-Mulish font-[700]">
                Total Estimated Value
              </span>
            </div>
            <div className="order-1">
              <img
                src={process.env.PUBLIC_URL + "/icons/pound.png"}
                className="w-[88px] h-[88px]"
                alt=""
              />
            </div>
          </div>
          <div className="">
            <Link to="/opportunity/add">
              <div className="py-[20px] px-[20px] font-[400] text-[20px] justify-center text-white bg-[#55A14A] w-full rounded-[12px] flex gap-[20px]">
                <img
                  src={process.env.PUBLIC_URL + "/icons/plus.png"}
                  alt=""
                  className="w-[24px]"
                />
                <span>Add Opportunity</span>
              </div>
            </Link>
          </div>
        </div>
        <div className="w-full md:w-[72%] md:flex gap-[24px] flex-col bg-[#F8F8F8] rounded-[12px] p-6">
          <div className="flex flex-row justify-between pb-[20px] border-b border-[#E1E1E1] border-solid">
            <div>
              <span className="font-Inter font-[600] text-[18px] leading-[21px] text-[#1C1C20]">
                Search Your Opportunity
              </span>
            </div>
            <div>
              <span className="cursor-pointer" onClick={handleResetFilterForm}>
                <img
                  src={process.env.PUBLIC_URL + "/icons/reset.png"}
                  alt=""
                  className="w-[24px] cursor-pointer"
                />
              </span>
            </div>
          </div>
          <Formik
            innerRef={formRef}
            initialValues={{
              customer_id: [],
              sales_executive: "",
              progress_status: "",
              win_probability: "",
              expected_close_date: "",
              next_contact_date: "",
            }}
            // validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({
              isSubmitting,
              values,
              errors,
              touched,
              setFieldValue,
              resetForm,
              handleSubmit,
            }) => (
              <Form>
                <div className="md:grid md:grid-cols-3 gap-3">
                  <div className="mb-2">
                    <label
                      className="font-Inter font-[400] text-[15px] text-[#9E9E9E] leading-[17px]"
                      htmlFor="customer_id"
                    >
                      Customer
                    </label>
                    <div className="relative">
                      <Field
                        as="select"
                        className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded-[12px] leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        id="customer_id"
                        name="customer_id"
                        multiple={true}
                        onChange={(e) => {
                          const options = e.target.options;
                          const selectedCustomers = [];
                          for (let i = 0; i < options.length; i++) {
                            if (options[i].selected) {
                              selectedCustomers.push(options[i].value);
                            }
                          }
                          setFieldValue("customer_id", selectedCustomers);
                        }}
                      >
                        <option value={""}>Select Customer</option>
                        {customerData.map((customer) => (
                          <option value={customer.id} key={customer.id}>
                            {customer.customer_name}
                          </option>
                        ))}
                      </Field>
                      <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                        <svg
                          className="fill-current h-4 w-4"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                        >
                          <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                        </svg>
                      </div>
                    </div>
                  </div>

                  <div className="mb-2">
                    <label
                      className="font-Inter font-[400] text-[15px] text-[#9E9E9E] leading-[17px]"
                      htmlFor="sales_executive"
                    >
                      Sales Executive
                    </label>
                    <div className="relative">
                      <Field
                        as="select"
                        className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded-[12px] leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        id="sales_executive"
                        name="sales_executive"
                        multiple={true}
                        onChange={(e) => {
                          const options = e.target.options;
                          const selectedSales_exec = [];
                          for (let i = 0; i < options.length; i++) {
                            if (options[i].selected) {
                              selectedSales_exec.push(options[i].value);
                            }
                          }
                          setFieldValue("sales_executive", selectedSales_exec);
                        }}
                      >
                        <option value={""}>Select Sales Executive</option>
                        {userData.map((user) => (
                          <option value={user.id} key={user.id}>
                            {user.firstname} {user.lastname}
                          </option>
                        ))}
                      </Field>
                      <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                        <svg
                          className="fill-current h-4 w-4"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                        >
                          <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                        </svg>
                      </div>
                    </div>
                    {errors.sales_executive && touched.sales_executive ? (
                      <div className="text-red-700">
                        {errors.sales_executive}
                      </div>
                    ) : null}
                  </div>

                  <div className="mb-2">
                    <label
                      className="font-Inter font-[400] text-[15px] text-[#9E9E9E] leading-[17px]"
                      htmlFor="progress_status"
                    >
                      Progress Status
                    </label>
                    <div className="relative">
                      <Field
                        as="select"
                        className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded-[12px] leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        id="progress_status"
                        name="progress_status"
                        multiple={true}
                        onChange={(e) => {
                          const options = e.target.options;
                          const selectedProgStat = [];
                          for (let i = 0; i < options.length; i++) {
                            if (options[i].selected) {
                              selectedProgStat.push(options[i].value);
                            }
                          }
                          setFieldValue("progress_status", selectedProgStat);
                        }}
                      >
                        <option value={""}>Select Progress Status</option>
                        {options
                          .filter(
                            (option) =>
                              option.ui_name === "Opportunity" &&
                              option.field_name === "Progress_Status"
                          )
                          .map((option) => (
                            <option
                              key={option.id}
                              value={option.id}
                              selected={option.is_default ? true : false}
                            >
                              {option.field_code}
                            </option>
                          ))}
                      </Field>
                      <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                        <svg
                          className="fill-current h-4 w-4"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                        >
                          <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                        </svg>
                      </div>
                    </div>
                    {errors.progress_status && touched.progress_status ? (
                      <div className="text-red-700">
                        {errors.progress_status}
                      </div>
                    ) : null}
                  </div>

                  <div className="mb-2">
                    <label
                      className="font-Inter font-[400] text-[15px] text-[#9E9E9E] leading-[17px]"
                      htmlFor="win_probability"
                    >
                      Win Probability
                    </label>
                    <div className="relative">
                      <Field
                        as="select"
                        className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded-[12px] leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        id="win_probability"
                        name="win_probability"
                        multiple={true}
                        onChange={(e) => {
                          const options = e.target.options;
                          const selectedWinProb = [];
                          for (let i = 0; i < options.length; i++) {
                            if (options[i].selected) {
                              selectedWinProb.push(options[i].value);
                            }
                          }
                          setFieldValue("win_probability", selectedWinProb);
                        }}
                      >
                        <option value={""}>Select Win Probability</option>
                        {options
                          .filter(
                            (option) =>
                              option.ui_name === "Opportunity" &&
                              option.field_name === "Win_Probability"
                          )
                          .map((option) => (
                            <option
                              key={option.id}
                              value={option.id}
                              selected={option.is_default ? true : false}
                            >
                              {option.field_code}
                            </option>
                          ))}
                      </Field>
                      <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                        <svg
                          className="fill-current h-4 w-4"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                        >
                          <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                        </svg>
                      </div>
                    </div>
                    {errors.win_probability && touched.win_probability ? (
                      <div className="text-red-700">
                        {errors.win_probability}
                      </div>
                    ) : null}
                  </div>

                  <div className="mb-2">
                    <label
                      className="font-Inter font-[400] text-[15px] text-[#9E9E9E] leading-[17px]"
                      htmlFor="expected_close_date"
                    >
                      Close Date
                    </label>
                    <Field
                      className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded-[12px] leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      type="date"
                      id="expected_close_date"
                      name="expected_close_date"
                      onChange={(e) => {
                        setFieldValue("expected_close_date", e.target.value);
                      }}
                    />
                    {errors.expected_close_date &&
                    touched.expected_close_date ? (
                      <div className="text-red-700">
                        {errors.expected_close_date}
                      </div>
                    ) : null}
                  </div>

                  <div className="mb-2">
                    <label
                      className="font-Inter font-[400] text-[15px] text-[#9E9E9E] leading-[17px]"
                      htmlFor="next_contact_date"
                    >
                      Next Contact Date
                    </label>
                    <Field
                      className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded-[12px] leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      type="date"
                      id="next_contact_date"
                      name="next_contact_date"
                      onChange={(e) => {
                        setFieldValue("next_contact_date", e.target.value);
                      }}
                    />
                    {errors.next_contact_date && touched.next_contact_date ? (
                      <div className="text-red-700">
                        {errors.next_contact_date}
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="flex bg-[#F8F8F8] rounded-[12px] mt-2">
                  <div>
                    <button
                      type="submit"
                      name="submit"
                      className={`py-[10px] px-[70px] text-center text-[#55A14A] border border-[#55A14A] w-full rounded-[12px] text-[16px] ${
                        isSubmitting ? "bg-gray-300" : "bg-white"
                      }`}
                      disabled={isSubmitting}
                    >
                      {isSubmitting ? (
                        <FontAwesomeIcon icon={faSpinner} spin />
                      ) : (
                        "Search"
                      )}
                    </button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
        {/* <div className="w-[30%] bg-[#000] rounded-[12px]"></div> */}
      </div>
      <div className="">
        <div className="flex justify-between items-center">
          <div className="leading-[30px] font-Mulish font-[700] text-[24px]">
            <span className="">List Of Opportunities</span>
          </div>
        </div>
        <div className="md:p-5 bg-[#F8F8F8] mt-[20px] rounded-l-[12px]">
          <div className="flex flex-col">
            <div className="md:flex justify-between items-center p-[12px]">
              <div className="mb-[16px] md:mb-0">
                <span
                  className="flex gap-[10px] font-[400] text-[16px] leading-[20px] text-[#4F42DB] cursor-pointer"
                  onClick={showDeletedRecord}
                >
                  <img src={process.env.PUBLIC_URL + "/icons/eye.png"} alt="" />{" "}
                  {isDeleted ? "Hide Deleted Records" : "Show Deleted Record"}
                </span>
              </div>
              <div className="relative">
                <input
                  className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-4 pl-[60px] rounded-[12px] leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  type="text"
                  id="search"
                  name="search"
                  onChange={(e) => {
                    setSearch(e.target.value);
                    debouncedSearch(e.target.value);
                  }}
                />
                <div className="pointer-events-none absolute inset-y-0 flex items-center px-5 text-gray-700">
                  <img
                    src={process.env.PUBLIC_URL + "/icons/search.png"}
                    alt=""
                  />
                </div>
              </div>
            </div>

            
            {!loading && opportunities.length > 0 ? (
              <Formik
                initialValues={{ opportunities: opportunities || [] }}
                validationSchema={validationSchema}
                enableReinitialize
                onSubmit={handleBulkEditSubmit}
              >
                {({
                  isSubmitting,
                  values,
                  errors,
                  touched,
                  setFieldValue,
                }) => (
                  <Form>
                    <FieldArray name="opportunities">
                    {({ insert, remove, push }) => (
                      <div className="overflow-x-auto">
                      <div className="min-w-[1200px]">
                        <div className="sm:rounded-lg mt-5 w-full">
                          <table className="w-full border-collapse">
                            <thead>
                              <tr className="bg-[#F8F8F8]">
                                <th className="px-2 py-3 text-center font-[Inter] font-[600] text-[14px] text-[#6D6D6F]">Id</th>
                                <th className="px-2 py-3 text-center font-[Inter] font-[600] text-[14px] text-[#6D6D6F]">Customer</th>
                                <th className="px-2 py-3 text-center font-[Inter] font-[600] text-[14px] text-[#6D6D6F]">Site</th>
                                <th className="px-2 py-3 text-center font-[Inter] font-[600] text-[14px] text-[#6D6D6F]">Opportunity</th>
                                <th className="px-2 py-3 text-center font-[Inter] font-[600] text-[14px] text-[#6D6D6F]">Expected Close Date</th>
                                <th className="px-2 py-3 text-center font-[Inter] font-[600] text-[14px] text-[#6D6D6F]">Progress Status</th>
                                <th className="px-2 py-3 text-center font-[Inter] font-[600] text-[14px] text-[#6D6D6F]">Win Probability</th>
                                <th className="px-2 py-3 text-center font-[Inter] font-[600] text-[14px] text-[#6D6D6F]">Next Contact Date</th>
                                <th className="px-2 py-3 text-center font-[Inter] font-[600] text-[14px] text-[#6D6D6F]">Estimated Value</th>
                                <th className="px-2 py-3 text-center font-[Inter] font-[600] text-[14px] text-[#6D6D6F]">Options</th>
                              </tr>
                            </thead>
                            <tbody>
                              {values.opportunities.length > 0 &&
                                values.opportunities.map((opportunity, index) => (
                                  <tr
                                    className="bg-white border border-gray-200 rounded-lg"
                                    key={index}
                                  >
                                    <td className="px-2 py-3">
                                      <Field
                                        className="w-full bg-white border border-gray-200 text-gray-700 rounded py-2 px-3"
                                        id={`opportunities.${index}.id`}
                                        name={`opportunities.${index}.id`}
                                        disabled
                                      />
                                      <ErrorMessage name={`opportunities.${index}.id`} />
                                    </td>
                                    <td className="px-2 py-3">
                                      <Field
                                        className="w-full bg-white border border-gray-200 text-gray-700 rounded py-2 px-3 focus:outline-none"
                                        id={`opportunities.${index}.customer_name`}
                                        name={`opportunities.${index}.customer_name`}
                                        disabled
                                      />
                                      <ErrorMessage name={`opportunities.${index}.customer_name`} />
                                    </td>
                                    <td className="px-2 py-3">
                                      <Field
                                        className="w-full bg-white border border-gray-200 text-gray-700 rounded py-2 px-3 focus:outline-none"
                                        id={`opportunities.${index}.site_name`}
                                        name={`opportunities.${index}.site_name`}
                                        disabled
                                      />
                                      <ErrorMessage name={`opportunities.${index}.site_name`} />
                                    </td>
                                    <td className="px-2 py-3">
                                      <Field
                                        className="w-full bg-white border border-gray-200 text-gray-700 rounded py-2 px-3 focus:outline-none"
                                        id={`opportunities.${index}.opportunity_name`}
                                        name={`opportunities.${index}.opportunity_name`}
                                        disabled
                                      />
                                      <ErrorMessage name={`opportunities.${index}.opportunity_name`} />
                                    </td>
                                    <td className="px-2 py-3">
                                      <Field
                                        className="w-full bg-white border border-gray-200 text-gray-700 rounded py-2 px-3 focus:outline-none"
                                        id={`opportunities.${index}.expected_close_date`}
                                        name={`opportunities.${index}.expected_close_date`}
                                        type="date"
                                      />
                                      <ErrorMessage name={`opportunities.${index}.expected_close_date`} />
                                    </td>
                                    <td className="px-2 py-3">
                                      <Field
                                        as="select"
                                        className="w-full bg-white border border-gray-200 text-gray-700 rounded py-2 px-3 focus:outline-none"
                                        id={`opportunities.${index}.progress_status`}
                                        name={`opportunities.${index}.progress_status`}
                                        onChange={(e) => {
                                          setFieldValue(`opportunities.${index}.progress_status`, e.target.value);
                                        }}
                                      >
                                        <option value={""}>Select Progress Status</option>
                                        {options
                                          .filter(
                                            (option) =>
                                              option.ui_name === "Opportunity" &&
                                              option.field_name === "Progress_Status"
                                          )
                                          .map((option) => (
                                            <option key={option.id} value={option.id}>
                                              {option.field_code}
                                            </option>
                                          ))}
                                      </Field>
                                      <ErrorMessage name={`opportunities.${index}.progress_status`} />
                                    </td>
                                    <td className="px-2 py-3">
                                      <Field
                                        as="select"
                                        className="w-full bg-white border border-gray-200 text-gray-700 rounded py-2 px-3 focus:outline-none"
                                        id={`opportunities.${index}.win_probability`}
                                        name={`opportunities.${index}.win_probability`}
                                        onChange={(e) => {
                                          setFieldValue(`opportunities.${index}.win_probability`, e.target.value);
                                        }}
                                      >
                                        <option value={""}>Select Win Probability</option>
                                        {options
                                          .filter(
                                            (option) =>
                                              option.ui_name === "Opportunity" &&
                                              option.field_name === "Win_Probability"
                                          )
                                          .map((option) => (
                                            <option key={option.id} value={option.id}>
                                              {option.field_code}
                                            </option>
                                          ))}
                                      </Field>
                                      <ErrorMessage name={`opportunities.${index}.win_probability`} />
                                    </td>
                                    <td className="px-2 py-3">
                                      <Field
                                        className="w-full bg-white border border-gray-200 text-gray-700 rounded py-2 px-3 focus:outline-none"
                                        id={`opportunities.${index}.next_contact_date`}
                                        name={`opportunities.${index}.next_contact_date`}
                                        type="date"
                                      />
                                      <ErrorMessage name={`opportunities.${index}.next_contact_date`} />
                                    </td>
                                    <td className="px-2 py-3">
                                      <Field
                                        className="w-full bg-white border border-gray-200 text-gray-700 rounded py-2 px-3 focus:outline-none"
                                        id={`opportunities.${index}.estimated_value`}
                                        name={`opportunities.${index}.estimated_value`}
                                      />
                                      <ErrorMessage name={`opportunities.${index}.estimated_value`} />
                                    </td>
                                    <td className="px-2 py-3 flex gap-2">
                                      <Link to={`/opportunity/${opportunity.id}`}>
                                        <img
                                          src={process.env.PUBLIC_URL + "/icons/edit.png"}
                                          alt="edit"
                                          className="w-10 bg-teal-100 rounded-lg"
                                        />
                                      </Link>
                                      <span className="cursor-pointer" onClick={() => handleDelete(opportunity.uniqueId)}>
                                        <img
                                          src={process.env.PUBLIC_URL + "/icons/delete.png"}
                                          alt="delete"
                                          className="w-10 bg-red-100 rounded-lg"
                                        />
                                      </span>
                                    </td>
                                  </tr>
                                ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>                       
                    )}
                  </FieldArray>

                  <div className="flex justify-end mt-5">
                    <button
                      type="submit"
                      className={`py-2 px-8 text-white text-[18px] rounded-lg ${isSubmitting ? "bg-gray-300" : "bg-black"}`}
                      disabled={isSubmitting}
                    >
                      {isSubmitting ? (
                        <FontAwesomeIcon icon={faSpinner} spin />
                      ) : (
                        'Save Changes'
                      )}
                    </button>
                  </div>
                </Form>
                )}
              </Formik>
            )
            : (
            <div className="text-center">
              <FontAwesomeIcon
                icon={faSpinner}
                size="2xl"
                style={{ color: "#55A14A" }}
                spin
              />
            </div>
          )}
          </div>
        </div>
      </div>
      <div className="flex justify-between items-center mt-[20px] gap-[40px] md:p-5 bg-[#F8F8F8] rounded-l-[12px]">
        <div className="hidden md:block font-Inter font-[300] text-nowrap">
          <span>
            Showing <b>{page}</b> of <b>{Math.ceil(totalCount / limit)}</b>{" "}
            pages
          </span>
        </div>
        <div className="w-full flex justify-end">
          <ReactPaginate
            className="react_pagination_ul"
            breakLabel={
              <span className="w-10 h-10 flex justify-center items-center bg-white rounded-md mr-4">
                <FontAwesomeIcon icon={faEllipsis} size="xl" />
              </span>
            }
            nextLabel={
              <span className="w-10 h-10 flex justify-center items-center bg-white rounded-md">
                <FontAwesomeIcon icon={faAngleRight} size="xl" />
              </span>
            }
            onPageChange={handlePageClick}
            pageCount={Math.ceil(totalCount / limit)}
            previousLabel={
              <span className="w-10 h-10 flex justify-center items-center bg-white rounded-md mr-4">
                <FontAwesomeIcon icon={faAngleLeft} size="xl" />
              </span>
            }
            activeClassName="bg-black text-white"
            pageClassName="block text-black  hover:bg-black hover:text-white rounded-md  mr-4"
            pageLinkClassName="w-10 h-10 flex justify-center items-center"
            containerClassName="flex items-center justify-center "
          />
        </div>
      </div>
    </div>
  );
}

export default ListOpportunity;
