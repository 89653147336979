import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { api } from "../../../api/ApiCall";

export const listCustomerSites = createAsyncThunk(
  "listCustomerSites",
  async ({ customer_id, search = "", page = 1, limit = 10 }) => {
    const request = await api(
      `api/admin/customer_sites/${customer_id}?search=${search}&page=${page}&limit=${limit}`,
      "",
      "get"
    );
    return request;
  }
);

export const getCustomerSiteById = createAsyncThunk(
  "getCustomerSiteById",
  async (data) => {
    const request = await api(`api/admin/customer_site/${data}`, "", "get");
    return request;
  }
);

export const getCustomerSitesByCustomerForUI = createAsyncThunk(
  "getCustomerSitesByCustomerForUI",
  async ({ customer_id }) => {
    const request = await api(
      `api/admin/customer_sites_by_customer/${customer_id}`,
      "",
      "get"
    );
    return request;
  }
);

export const addCustomerSite = createAsyncThunk(
  "addCustomerSite",
  async (data) => {
    const request = await api("api/admin/customer_site", data, "post");
    return request;
  }
);

export const updateCustomerSite = createAsyncThunk(
  "updateCustomerSite",
  async ({ data, id }) => {
    const request = await api(`api/admin/customer_site/${id}`, data, "post");
    return request;
  }
);

export const getCustomerSiteForUI = createAsyncThunk(
  "getCustomerSiteForUI",
  async () => {
    const request = await api(`api/admin/customersiteforui`, "", "get");
    return request;
  }
);

const customerSiteSlice = createSlice({
  name: "customerSites",
  initialState: {
    loading: false,
    customerSites: [],
    success: null,
    error: null,
    totalCount: 0,
  },
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(listCustomerSites.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(listCustomerSites.fulfilled, (state, action) => {
        state.customerSites = action?.payload?.rows;
        state.totalCount = action?.payload.count;
        state.loading = false;
        state.error = null;
      })
      .addCase(listCustomerSites.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(addCustomerSite.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(addCustomerSite.fulfilled, (state, action) => {
        state.loading = false;
        state.customerSites = action.payload;
        state.error = null;
      })
      .addCase(addCustomerSite.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(updateCustomerSite.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateCustomerSite.fulfilled, (state, action) => {
        state.loading = false;
        // Update the user in the users array
        state.customerSites = state.customerSites.map((option) => {
          if (option.id === action.payload.id) {
            return action.payload;
          }
          return option;
        });
        state.success = action.payload.message;
        state.error = null;
      })
      .addCase(updateCustomerSite.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});
export default customerSiteSlice.reducer;
