import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { api } from "../../../api/ApiCall";

export const loginuser = createAsyncThunk(
    'auth/loginuser',
    async(userCredentials)=>{
        const request = await api("api/admin/login", userCredentials, "post");
        localStorage.setItem("userSession",JSON.stringify({...request}));
        return request;
    }
)
const authSlice = createSlice({
    name: 'userSession',
    initialState:{
        loading: false,
        user: null,
        error: null
    },
    reducers:{
      setAuthUser: (state,action)=>{
        state.user = action.payload;
      },
      removeAuthUser: (state)=>{
        state.user = null;
      }
    },
    extraReducers(builder) {
        builder
          .addCase(loginuser.pending, (state) => {
            state.loading = true;
            state.user = null;
            state.error = null;
          })
          .addCase(loginuser.fulfilled, (state, action) => {
            state.loading = false;
            state.user = action.payload;
            state.error = null;
          })
          .addCase(loginuser.rejected, (state, action) => {
            state.loading = false;
            state.user = null;
            state.error = action.error.message;
          })
    }
})
export const { setAuthUser, removeAuthUser } = authSlice.actions;
export default authSlice.reducer;