import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { api } from "../../../api/ApiCall";

export const listExpenses = createAsyncThunk(
  "listExpenses",
  async ({ user_id, search = "", page = 1, limit = 10 }) => {
    const request = await api(
      `api/admin/expenses/${user_id}?search=${search}&page=${page}&limit=${limit}`,
      "",
      "get"
    );
    return request;
  }
);

export const getExpenseById = createAsyncThunk(
  "getExpenseById",
  async (data) => {
    const request = await api(`api/admin/expense/${data}`, "", "get");
    return request;
  }
);

export const addExpense = createAsyncThunk("addExpense", async (data) => {
  const request = await api("api/admin/expense", data, "postFile");
  return request;
});

export const updateExpense = createAsyncThunk(
  "updateExpense",
  async ({ data, id }) => {
    const request = await api(`api/admin/expense/${id}`, data, "postFile");
    return request;
  }
);

export const searchExpenses = createAsyncThunk(
  "searchExpenses",
  async ({ user_id, category, expense_date }) => {
    const request = await api(
      `api/admin/searchexpenses?user_id=${user_id}&category=${category}&expense_date=${expense_date}`,
      "",
      "get"
    );
    return request;
  }
);

export const deleteExpense = createAsyncThunk("deleteExpense", async (id) => {
  const request = await api(`api/admin/expense/${id}`, {}, "delete");
  return request;
});

const expensesSlice = createSlice({
  name: "expenses",
  initialState: {
    loading: false,
    expenses: [],
    success: null,
    error: null,
    totalCount: 0,
  },
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(listExpenses.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(listExpenses.fulfilled, (state, action) => {
        state.expenses = action?.payload?.rows;
        state.totalCount = action?.payload.count;
        state.loading = false;
        state.error = null;
      })
      .addCase(listExpenses.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(searchExpenses.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(searchExpenses.fulfilled, (state, action) => {
        state.expenses = action?.payload?.rows;
        state.totalCount = action?.payload.count;
        state.loading = false;
        state.error = null;
      })
      .addCase(searchExpenses.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(addExpense.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(addExpense.fulfilled, (state, action) => {
        state.loading = false;
        state.expenses = action.payload;
        state.error = null;
      })
      .addCase(addExpense.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(updateExpense.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateExpense.fulfilled, (state, action) => {
        state.loading = false;
        state.expenses = state.expenses.map((option) => {
          if (option.id === action.payload.id) {
            return action.payload;
          }
          return option;
        });
        state.success = action.payload.message;
        state.error = null;
      })
      .addCase(updateExpense.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(deleteExpense.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteExpense.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
      })
      .addCase(deleteExpense.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});
export default expensesSlice.reducer;
